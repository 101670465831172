/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/no-nesting */
import './ViewNetworks.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { storeCurrentPath } from '../LocalRedirectUrlStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Table, Input, Spinner, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const defaultVnet = {
    name: '',
    id: '',
    properties: {
        resourceGuid: '',
        addressSpace: {
            addressPrefixes: [],
        },
        subnets: [
            {
                properties: {
                    addressPrefix: '',
                },
            },
        ],
        virtualNetworkPeerings: [
            {
                properties: {
                    peeringState: '',
                    allowForwardedTraffic: '',
                    remoteAddressSpace: {
                        addressPrefixes: [],
                    },
                },
            },
        ],
    },
    subscriptionId: '',
};

const defaultSubscription = {
    name: '',
    subscriptionId: '',
};

class ViewNetworks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 20,
            loading: true,
            filterData: [],
            searchText: '',
            currentPage: 0,
            vnets: [defaultVnet],
            subscriptions: [defaultSubscription],
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            this.secureLogin();
        });
    }

    handleChange(e) {
        this.setState(e);
    }

    handleInputChange(e) {
        var searchText = e.target.value.toLowerCase();
        this.setState({
            searchText,
            filterData: this.state.vnets.filter(
                (vnet) =>
                    vnet.name.toLowerCase().includes(searchText) ||
                    vnet.properties.addressSpace.addressPrefixes.toLocaleString().includes(searchText) ||
                    this.state.subscriptions
                        .find((obj) => obj.subscriptionId === vnet.subscriptionId)
                        .name.toLowerCase()
                        .includes(searchText)
            ),
            currentPage: 0,
        });
    }

    handleClick(event, index) {
        event.preventDefault();
        this.setState({
            currentPage: index,
        });
    }

    secureLogin() {
        var accessToken = window.sessionStorage.getItem('NusseToken');

        if (!accessToken) {
            storeCurrentPath('/start/vnets');
            return null;
        }

        var headers = new Headers();
        headers.append('Ocp-Apim-Subscription-Key', 'b835365a0dba461c8afa4d8ab79a4745');
        var bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);

        var options = {
            credentials: 'include',
            mode: 'cors',
            headers: headers,
            method: 'GET',
        };

        this.fetchSubscriptions(options);
        this.fetchVNets(options);
    }

    fetchVNets(options) {
        fetch(
            `https://ece46ec4-6f9c-489b-8fe5-146a89e11635.tech-02.net/nusse-backend/vnet/all`,
            options
        )
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    vnets: result,
                    filterData: result,
                });
            })
            .catch((error) => {
                console.log(`error: ${error}`);
                window.open(`/`, '_self');
            });
    }

    fetchSubscriptions(options) {
        fetch(
            `https://ece46ec4-6f9c-489b-8fe5-146a89e11635.tech-02.net/nusse-backend/subscription/all`,
            options
        )
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    subscriptions: result,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(`error: ${error}`);
                window.open(`/`, '_self');
            });
    }

    goToVNet(guid) {
        window.open(`/start/vnets/detailed?GUID=${encodeURIComponent(guid)}`, '_self');
    }

    displayVNets() {
        const { currentPage, pageSize, filterData, vnets, subscriptions } = this.state;
        this.pagesCount = Math.ceil(filterData.length / pageSize);

        filterData.sort((a, b) => {
            if (
                a.properties.addressSpace.addressPrefixes[0] <
                b.properties.addressSpace.addressPrefixes[0]
            ) {
                return -1;
            }
            if (
                a.properties.addressSpace.addressPrefixes[0] >
                b.properties.addressSpace.addressPrefixes[0]
            ) {
                return 1;
            }
            return 0;
        });

        return !vnets.length || !subscriptions.length
            ? null
            : filterData.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((vnet) => {
                  var subName;
                  try {
                      subName = subscriptions.find(
                          (obj) => obj.subscriptionId === vnet.subscriptionId
                      ).name;
                  } catch (e) {
                      return null;
                  } finally {
                      const props = vnet.properties;
                      return (
                          <tr key={vnet.id} onClick={() => this.goToVNet(props.resourceGuid)}>
                              <td>{vnet.name}</td>
                              <td>{subName}</td>
                              <td>{props.addressSpace.addressPrefixes.toString()}</td>
                              <td>{props.subnets.length}</td>
                              <td>{props.virtualNetworkPeerings.length}</td>
                              <td>
                                  <FontAwesomeIcon icon="chevron-right" />
                              </td>
                          </tr>
                      );
                  }
              });
    }

    render() {
        /* jshint ignore:start */

        const { loading, currentPage, pageSize, filterData, searchText } = this.state;
        this.pagesCount = Math.ceil(filterData.length / pageSize);

        return (
            <main className="body">
                <Link to="">
                    <FontAwesomeIcon icon="chevron-up" size="3x" />
                </Link>

                <div className="wallpaper">
                    <div className="container">
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div>
                                <header>
                                    <h1>Azure VNets</h1>
                                </header>
                                <div className="row left-align">
                                    <div className="input-group">
                                        <Input
                                            autoFocus
                                            type="search"
                                            value={searchText}
                                            placeholder="Filter by name of VNet or Subscription"
                                            onChange={this.handleInputChange}
                                        />{' '}
                                        <br />
                                    </div>
                                    <div>
                                        <Button
                                            value="aks"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> AKS
                                        </Button>
                                        <Button
                                            value="test"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Test
                                        </Button>
                                        <Button
                                            value="prod"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Prod
                                        </Button>
                                        <Button
                                            value="platform"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Platform
                                        </Button>
                                        <Button
                                            value="plattform prod"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Plattform Prod
                                        </Button>
                                        <Button
                                            value="laboratory"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Laboratory
                                        </Button>
                                        <Button
                                            value="splunk"
                                            onClick={this.handleInputChange}
                                            className="toggler"
                                        >
                                            <FontAwesomeIcon icon={faSearch} /> Splunk
                                        </Button>
                                    </div>

                                    <div className="col">
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Subscription</th>
                                                    <th>Address Prefixes</th>
                                                    <th># Subnets</th>
                                                    <th># Peerings</th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.displayVNets()}</tbody>
                                        </Table>

                                        <Pagination>
                                            <PaginationItem disabled={currentPage <= 0}>
                                                <PaginationLink
                                                    previous
                                                    className="prev-next-buttons"
                                                    onClick={(e) =>
                                                        this.handleClick(e, currentPage - 1)
                                                    }
                                                    href="#"
                                                />
                                            </PaginationItem>
                                            {[...Array(this.pagesCount)].map((currentPageno, i) => (
                                                <PaginationItem active={i === currentPage} key={i}>
                                                    <PaginationLink
                                                        className="page-numbers"
                                                        onClick={(e) => this.handleClick(e, i)}
                                                        href="#"
                                                    >
                                                        {i + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem
                                                disabled={currentPage >= this.pagesCount - 1}
                                            >
                                                <PaginationLink
                                                    next
                                                    className="prev-next-buttons"
                                                    onClick={(e) =>
                                                        this.handleClick(e, currentPage + 1)
                                                    }
                                                    href="#"
                                                />
                                            </PaginationItem>
                                        </Pagination>
                                    </div>

                                    <i>
                                        Showing [{currentPage * pageSize + 1}-
                                        {(currentPage + 1) * pageSize < filterData.length
                                            ? (currentPage + 1) * pageSize
                                            : filterData.length}
                                        ] out of {filterData.length}.
                                    </i>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default ViewNetworks;
