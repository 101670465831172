/* eslint-disable no-alert */
import './CreateCronJob.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { parseExpression } from 'cron-parser';
import { UncontrolledCollapse } from 'reactstrap';
import Env from './Env';
import React from 'react';

const defaultEnvs = Array.from({ length: 10 }, (_, i) => ({
    default: '',
    dr: '',
    index: i,
    isOpen: false,
    mt: '',
    prod: '',
    uat: '',
    vName: '',
}));

const defaultPilot = true;
const defaultSchedule = '59 23 30 12 *';
const defaultEntrypoint = [null, null, null, null, null, null];
const defaultCommand = [null, null, null, null, null, null];

class CreateCronJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            docker: { image: '', repository: '' },
            entrypoint: defaultEntrypoint,
            envs: defaultEnvs.map((env) => Object.assign({}, env)),
            exec: { pilot: false, command: defaultCommand },
            identity: '',
            name: '',
            namespace: '',
            params: '',
            schedule: defaultSchedule,
            scheduleExpanded: [],
        };

        this.handleAddEnv = this.handleAddEnv.bind(this);
        this.handleCommmand = this.handleCommand.bind(this);
        this.handleDockerImage = this.handleDockerImage.bind(this);
        this.handleDockerRepository = this.handleDockerRepository.bind(this);
        this.handleEntrypoint = this.handleEntrypoint.bind(this);
        this.handleEnv = this.handleEnv.bind(this);
        this.handleIdentity = this.handleIdentity.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleNamespace = this.handleNamespace.bind(this);
        this.handlePilot = this.handlePilot.bind(this);
        this.handleRemoveEnv = this.handleRemoveEnv.bind(this);
        this.handleSchedule = this.handleSchedule.bind(this);
        this.setSchedule = this.setSchedule.bind(this);
        this.setMonthly = this.setMonthly.bind(this);
        this.setFifteenMinutes = this.setFifteenMinutes.bind(this);
        this.setOneAm = this.setOneAm.bind(this);
    }

    colease(values) {
        if (Array.isArray(values)) {
            while (values.length > 0) {
                if (values[values.length - 1] === null || values[values.length - 1] === '') {
                    values.pop();
                } else {
                    break;
                }
            }
        }
        return values;
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const command = JSON.parse(params.get('command')) || this.state.exec.command;
        const entrypoint = JSON.parse(params.get('entrypoint')) || this.state.entrypoint;
        const envs = JSON.parse(params.get('envs')) || this.state.envs;
        const identity = JSON.parse(params.get('identity')) || this.state.identity;
        const image = JSON.parse(params.get('image')) || this.state.docker.image;
        const name = JSON.parse(params.get('name')) || this.state.name;
        const namespace = JSON.parse(params.get('namespace')) || this.state.namespace;
        const pilot = JSON.parse(params.get('pilot')) || this.state.exec.pilot;
        const repository = JSON.parse(params.get('repository')) || this.state.docker.repository;
        const schedule = JSON.parse(params.get('schedule')) || this.state.schedule;
        this.setState(
            {
                docker: { image: image, repository: repository },
                entrypoint: entrypoint,
                envs: envs,
                exec: { pilot: pilot, command: command },
                identity: identity,
                name: name,
                namespace: namespace,
                schedule: schedule,
            },
            () => {
                this.setState((s) => ({
                    params: this.createParams(s),
                }));
            }
        );
    }

    copy(text, filename) {
        const clipboard = navigator.clipboard;
        const written = clipboard.writeText(text);
        written
            .then(() => {
                alert(`${filename} copied to clip board`);
            })
            .catch((err) => {
                alert(`Error in copying ${filename} to clip board`, err);
            });
    }

    createParams(newPipelineState) {
        var params = [];
        if (newPipelineState.docker.image !== '') {
            params.push(`image=${encodeURIComponent(newPipelineState.docker.image)}`);
        }

        if (newPipelineState.docker.repository !== '') {
            params.push(`repository=${encodeURIComponent(newPipelineState.docker.repository)}`);
        }

        if (newPipelineState.name !== '') {
            params.push(`name=${encodeURIComponent(newPipelineState.name)}`);
        }

        if (newPipelineState.namespace !== '') {
            params.push(`namespace=${encodeURIComponent(newPipelineState.namespace)}`);
        }

        if (newPipelineState.schedule !== defaultSchedule) {
            params.push(`schedule=${encodeURIComponent(newPipelineState.schedule)}`);
        }

        if (newPipelineState.identity !== '') {
            params.push(`identity=${encodeURIComponent(newPipelineState.identity)}`);
        }

        if (newPipelineState.pilot !== defaultPilot) {
            params.push(`pilot=${encodeURIComponent(newPipelineState.exec.pilot)}`);
        }

        if (newPipelineState.entrypoint !== []) {
            params.push(
                `entrypoint=${encodeURIComponent(JSON.stringify(newPipelineState.entrypoint))}`
            );
        }

        if (this.equalOrNull(newPipelineState.envs, defaultEnvs) === false) {
            params.push(`envs=${encodeURIComponent(JSON.stringify(newPipelineState.envs))}`);
        }

        if (newPipelineState.exec.command !== []) {
            params.push(
                `command=${encodeURIComponent(JSON.stringify(newPipelineState.exec.command))}`
            );
        }

        if (params.length > 0) {
            return `?${params.join('&')}`;
        }

        return '';
    }

    download(text) {
        const blob = new Blob([text], { type: 'text/plain' });
        return URL.createObjectURL(blob);
    }

    equalOrNull(object1, object2) {
        return object1 === null || JSON.stringify(object1) === JSON.stringify(object2);
    }

    handleCommand(e, index) {
        const command = this.state.exec.command;
        while (command.length <= index) {
            command.push(null);
        }
        if (command.length > index) {
            command[index] = e.target.value;
        }

        this.setState(
            (s) => ({
                exec: {
                    ...s.exec,
                    command: command,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleDockerImage(e) {
        this.setState(
            (s) => ({
                docker: {
                    ...s.docker,
                    image: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleDockerRepository(e) {
        this.setState(
            (s) => ({
                docker: {
                    ...s.docker,
                    repository: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleEntrypoint(e, index) {
        const entrypoint = this.state.entrypoint;
        while (entrypoint.length <= index) {
            entrypoint.push(null);
        }
        if (entrypoint.length > index) {
            entrypoint[index] = e.target.value;
        }

        this.setState(
            (s) => ({
                entrypoint: entrypoint,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleIdentity(e) {
        this.setState(
            {
                identity: e.target.value,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleName(e) {
        this.setState(
            {
                name: e.target.value,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleNamespace(e) {
        this.setState(
            {
                namespace: e.target.value,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handlePilot(e) {
        this.setState(
            (s) => ({
                exec: {
                    ...s.exec,
                    pilot: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    createNextThree(schedule) {
        var nextThree = [];
        try {
            var options = {
                currentDate: new Date(),
                iterator: true,
            };
            var interval = parseExpression(schedule, options);
            for (let i = 0; i < 3; i++) {
                const instant = interval.next();
                if (!instant.done) {
                    nextThree.push(instant.value.toISOString());
                }
            }
        } catch (error) {
            console.log(error.message);
        }
        return nextThree;
    }

    handleSchedule(e) {
        const schedule = e.target.value;
        const nextThree = this.createNextThree(schedule);

        this.setState(
            {
                schedule: schedule,
                scheduleExpanded: nextThree,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleAddEnv(_) {
        var envs = this.state.envs;
        for (var i = 0; i < envs.length; i++) {
            if (envs[i].isOpen === false) {
                envs[i].isOpen = true;
                break;
            }
        }

        this.setState({ envs: envs }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleRemoveEnv(_) {
        var envs = this.state.envs;
        for (var i = envs.length - 1; i >= 0; i--) {
            if (envs[i].isOpen === true) {
                envs[i].isOpen = false;
                envs[i].default = '';
                envs[i].uat = '';
                envs[i].mt = '';
                envs[i].prod = '';
                envs[i].dr = '';
                envs[i].vName = '';
                break;
            }
        }

        this.setState({ envs: envs }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleEnv(e) {
        this.setState(
            (state) => {
                const envs = state.envs;
                envs[e.index] = e;
                return {
                    envs,
                };
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    serializeCommand(commands) {
        return this.serializeStringArray(commands);
    }

    serializeEntrypoints(entrypoint) {
        return this.serializeStringArray(entrypoint);
    }

    serializeStringArray(values) {
        if (values === null) {
            return 'null';
        } else if (values.length === 0) {
            return '[]';
        } else {
            const serialized = `[ "${values.join('", "')}" ]`;
            return serialized;
        }
    }

    seralizeSchedule(times) {
        var text = [];
        times.forEach((time, i) => {
            text.push(
                <div key={i}>
                    <time dateTime={time}>{time}</time>
                </div>
            );
        });

        return text;
    }

    setMonthly(e) {
        this.setSchedule(e, '0 0 1 * *');
    }

    setFifteenMinutes(e) {
        this.setSchedule(e, '*/15 * * * *');
    }

    setOneAm(e) {
        this.setSchedule(e, '0 1 * * *');
    }

    setSchedule(e, schedule) {
        var scheduleExpanded = this.createNextThree(schedule);
        this.setState(
            {
                schedule: schedule,
            },
            () => {
                this.setState((s) => ({
                    scheduleExpanded: scheduleExpanded,
                    params: this.createParams(s),
                }));
            }
        );
    }

    createEntrypoint() {
        return `  entrypoint: ${this.serializeEntrypoints(this.colease(this.state.entrypoint))}`;
    }

    createCommand() {
        return `
    exec:
      command: ${this.serializeCommand(this.colease(this.state.exec.command))}`;
    }

    render() {
        var hasCommand = this.colease(this.state.exec.command).length !== 0;
        var command = this.createCommand();

        var hasEntrypoint = this.colease(this.state.entrypoint).length !== 0;
        var entrypoint = this.createEntrypoint();

        var hasEnvs = false;
        var envs = 'envs:\n';
        for (const ev of this.state.envs) {
            hasEnvs |= ev.isOpen && ev.vName !== '';
            if (ev.vName !== '') {
                envs +=
                    '  ' +
                    ev.vName +
                    ':' +
                    (ev.default !== '' ? '\n    _default: ' + ev.default : '') +
                    (ev.dr !== '' ? '\n    dr: ' + ev.dr : '') +
                    (ev.mt !== '' ? '\n    mt: ' + ev.mt : '') +
                    (ev.prod !== '' ? '\n    prod: ' + ev.prod : '') +
                    (ev.uat !== '' ? '\n    uat: ' + ev.uat : '');
            }
        }

        const cronJobYaml = `# Generated from
# ${window.location.protocol}//${window.location.host}${window.location.pathname}${
            this.state.params
        }
# You can allways visit this link to download this cronjob definition.
kind: Vibe
apiVersion: vipps.io/v1alphav1
aadpodidbinding: ${this.state.identity}
cronjob:
  image: acrvippsakscmn.azurecr.io/${this.state.docker.repository}/${this.state.docker.image}${
            hasEntrypoint ? `\n${entrypoint}` : ''
        }
  pilot:
    enabled: ${this.state.exec.pilot.toString()}${hasCommand ? command : ''}
  schedule: "${this.state.schedule}"${hasEnvs ? `\n${envs}` : ''}
metadata:
  name: ${this.state.name}
  namespace: ${this.state.namespace}
resourcesLimitsCpu: 1000m
resourcesLimitsMemory: 500Mi
resourcesRequestsCpu: 40m
resourcesRequestsMemory: 40Mi
`;
        const nextThree = this.seralizeSchedule(this.state.scheduleExpanded);
        /* jshint ignore:start */
        return (
            <div>
                <nav className="flow">
                    <span className="flow-item">
                        <Link to="/" title="Start">
                            <FontAwesomeIcon icon="chevron-left" size="3x" />
                        </Link>
                    </span>
                    <span className="flow-item">
                        <Link to={'/start/cron-job-release'} title="Release your CronJob">
                            <FontAwesomeIcon icon="chevron-right" size="3x" />
                        </Link>
                    </span>
                </nav>
                <main className="body">
                    <div className="container">
                        <header>
                            <h1>Create CronJob</h1>
                            <p>
                                <b>This page helps you create a cronjob</b>
                            </p>
                            <p>
                                A cronjob is a program which only runs on a given interval. You
                                decide what application to run by choosing a docker image.
                            </p>
                            <p>
                                Typically a cronjob is set up to run every hour, weekday, month or
                                any combination of these.
                            </p>
                            <p>
                                For the full documentation see
                                <a
                                    rel="noreferrer noopener"
                                    href="https://github.com/vippsas/vops/blob/main/docs/Vibes.md"
                                >
                                    Vibes.md
                                </a>
                                .
                            </p>
                            <h2>How To</h2>
                            <ol>
                                <li>
                                    Fill out this form. Click download cronjob.yaml. You will get a
                                    new file. Check that file in along with our application.
                                </li>
                                <li>
                                    Build your application as a docker image. Create a new release
                                    in azure devops. Visit{' '}
                                    <a href="https://nusse.vippstech.no/start/cron-job-release">
                                        Release your CronJob
                                    </a>{' '}
                                    and use that as a template for your release.
                                </li>
                            </ol>
                        </header>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="docker-image">docker image</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    name="docker-image"
                                    defaultValue={this.state.docker.image}
                                    required
                                    placeholder="<application>"
                                    onChange={this.handleDockerImage}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="docker-repository">docker repository</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    name="docker-repository"
                                    defaultValue={this.state.docker.repository}
                                    required
                                    placeholder="vippsas/netthandel"
                                    onChange={this.handleDockerRepository}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="name">job name</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    name="name"
                                    defaultValue={this.state.name}
                                    required
                                    placeholder=""
                                    onChange={this.handleName}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="aks-namespace">kubernetes namespace</label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    name="aks-namespace"
                                    defaultValue={this.state.namespace}
                                    required
                                    placeholder="team-netthandel"
                                    onChange={this.handleNamespace}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="pilot">pilot</label>

                                <span
                                    role="img"
                                    aria-label="tool tip CPU"
                                    id="pilot-tip"
                                    className="explainer"
                                >
                                    ❓
                                </span>
                                <UncontrolledCollapse toggler="#pilot-tip">
                                    Define a job to be run when a vibe is created/changed Useful for
                                    verifying that the image can be pulled and a command can be
                                    executed.
                                </UncontrolledCollapse>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="checkbox"
                                    name="pilot"
                                    checked={this.state.exec.pilot}
                                    onChange={this.handlePilot}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="command_0">command during pilot run</label>
                                <span
                                    role="img"
                                    aria-label="tool tip CPU"
                                    id="command-tip"
                                    className="explainer"
                                >
                                    ❓
                                </span>
                                <UncontrolledCollapse toggler="#command-tip">
                                    Define a custom command used for pilot runs.
                                </UncontrolledCollapse>
                            </div>
                            <div className="col-md-8">
                                <span className="cmd-container">
                                    <input
                                        type="text"
                                        name="command_0"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[0]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 0)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="command_1"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[1]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 1)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="command_2"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[2]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 2)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="command_3"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[3]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 3)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="command_4"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[4]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 4)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="command_5"
                                        className="cmd"
                                        disabled={!this.state.exec.pilot}
                                        defaultValue={this.state.exec.command[5]}
                                        required
                                        onChange={(e) => this.handleCommand(e, 5)}
                                    ></input>
                                </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="identity">
                                    run with{' '}
                                    <a
                                        rel="noreferrer noopener"
                                        href="https://github.com/Azure/aad-pod-identity#aad-pod-identity"
                                    >
                                        identity
                                    </a>
                                </label>
                            </div>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    name="identity"
                                    defaultValue={this.state.identity}
                                    required
                                    onChange={this.handleIdentity}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="schedule">schedule</label>
                            </div>
                            <div className="col-md-2">
                                <input
                                    data-testid="crontab"
                                    type="text"
                                    name="schedule"
                                    value={this.state.schedule}
                                    required
                                    placeholder="* * 1 * *"
                                    onChange={this.handleSchedule}
                                ></input>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-1">
                                <button
                                    className="size-0-5"
                                    name="15m"
                                    onClick={this.setFifteenMinutes}
                                >
                                    15m
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button className="size-0-5" name="1AM" onClick={this.setOneAm}>
                                    1AM
                                </button>
                            </div>
                            <div className="col-md-1">
                                <button
                                    className="size-0-5"
                                    name="monthly"
                                    data-testid="monthly"
                                    onClick={this.setMonthly}
                                >
                                    monthly
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">Next scheduled runs</div>
                            <div className="col-md-8">
                                <span id="schedule">{nextThree}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <h4>Entrypoint</h4>
                            </div>
                            <div className="col-md-8">
                                <span className="cmd-container">
                                    <input
                                        type="text"
                                        name="entrypoint_0"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[0]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 0)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="entrypoint_1"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[1]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 1)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="entrypoint_2"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[2]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 2)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="entrypoint_3"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[3]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 3)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="entrypoint_4"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[4]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 4)}
                                    ></input>
                                    <input
                                        type="text"
                                        name="entrypoint_5"
                                        className="cmd"
                                        defaultValue={this.state.entrypoint[5]}
                                        required
                                        onChange={(e) => this.handleEntrypoint(e, 5)}
                                    ></input>
                                </span>
                            </div>
                        </div>

                        <h4>Environment Variables</h4>
                        <button type="button" className="push" onClick={this.handleAddEnv}>
                            +
                        </button>
                        <button type="button" className="push" onClick={this.handleRemoveEnv}>
                            -
                        </button>
                        <Env
                            isOpen={this.state.envs[0].isOpen}
                            index={0}
                            vName={this.state.envs[0].vName}
                            default={this.state.envs[0].default}
                            uat={this.state.envs[0].uat}
                            mt={this.state.envs[0].mt}
                            prod={this.state.envs[0].prod}
                            dr={this.state.envs[0].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[1].isOpen}
                            index={1}
                            vName={this.state.envs[1].vName}
                            default={this.state.envs[1].default}
                            uat={this.state.envs[1].uat}
                            mt={this.state.envs[1].mt}
                            prod={this.state.envs[1].prod}
                            dr={this.state.envs[1].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[2].isOpen}
                            index={2}
                            vName={this.state.envs[2].vName}
                            default={this.state.envs[2].default}
                            uat={this.state.envs[2].uat}
                            mt={this.state.envs[2].mt}
                            prod={this.state.envs[2].prod}
                            dr={this.state.envs[2].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[3].isOpen}
                            index={3}
                            vName={this.state.envs[3].vName}
                            default={this.state.envs[3].default}
                            uat={this.state.envs[3].uat}
                            mt={this.state.envs[3].mt}
                            prod={this.state.envs[3].prod}
                            dr={this.state.envs[3].dr}
                            onChange={this.handleEnv}
                        />

                        <Env
                            isOpen={this.state.envs[4].isOpen}
                            index={4}
                            vName={this.state.envs[4].vName}
                            default={this.state.envs[4].default}
                            uat={this.state.envs[4].uat}
                            mt={this.state.envs[4].mt}
                            prod={this.state.envs[4].prod}
                            dr={this.state.envs[4].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[5].isOpen}
                            index={5}
                            vName={this.state.envs[5].vName}
                            default={this.state.envs[5].default}
                            uat={this.state.envs[5].uat}
                            mt={this.state.envs[5].mt}
                            prod={this.state.envs[5].prod}
                            dr={this.state.envs[5].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[6].isOpen}
                            index={6}
                            vName={this.state.envs[6].vName}
                            default={this.state.envs[6].default}
                            uat={this.state.envs[6].uat}
                            mt={this.state.envs[6].mt}
                            prod={this.state.envs[6].prod}
                            dr={this.state.envs[6].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[7].isOpen}
                            index={7}
                            vName={this.state.envs[7].vName}
                            default={this.state.envs[7].default}
                            uat={this.state.envs[7].uat}
                            mt={this.state.envs[7].mt}
                            prod={this.state.envs[7].prod}
                            dr={this.state.envs[7].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[8].isOpen}
                            index={8}
                            vName={this.state.envs[8].vName}
                            default={this.state.envs[8].default}
                            uat={this.state.envs[8].uat}
                            mt={this.state.envs[8].mt}
                            prod={this.state.envs[8].prod}
                            dr={this.state.envs[8].dr}
                            onChange={this.handleEnv}
                        />
                        <Env
                            isOpen={this.state.envs[9].isOpen}
                            index={9}
                            vName={this.state.envs[9].vName}
                            default={this.state.envs[9].default}
                            uat={this.state.envs[9].uat}
                            mt={this.state.envs[9].mt}
                            prod={this.state.envs[9].prod}
                            dr={this.state.envs[9].dr}
                            onChange={this.handleEnv}
                        />
                        <hr></hr>
                        <div className="row">
                            <div className="col">
                                <button
                                    type="button"
                                    className="push"
                                    onClick={() => this.copy(cronJobYaml, 'cronjob.yaml')}
                                >
                                    copy to clipboard
                                </button>
                                <a href={this.download(cronJobYaml)} download="cronjob.yaml">
                                    Download cronjob.yaml
                                </a>
                            </div>
                        </div>

                        <code>
                            <pre className="code" data-testid="yaml">
                                {cronJobYaml}
                            </pre>
                        </code>
                    </div>
                </main>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default CreateCronJob;
