import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');
/* jshint ignore:start */
if (root.hasChildNodes()) {
    ReactDOM.hydrate(
        <React.Fragment>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.Fragment>,
        root
    );
} else {
    ReactDOM.render(
        <React.Fragment>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.Fragment>,
        root
    );
}
/* jshint ignore:end */
reportWebVitals();
