import './FlowZero.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import CreateIdentity from './CreateIdentity';
import FlowNavigation from '../components/FlowNavigation';


class FlowZero extends React.Component {
    render() {
        /* jshint ignore:start */
        return (
            <div>
                <FlowNavigation 
                    previous="/"
                    next="/start/run-parameters"
                    currentStep={1}
                    numberOfSteps={5}
                >
                </FlowNavigation>
                <main className="body">
                    <CreateIdentity location={this.props.location} />
                </main>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default FlowZero;
