import './CreateDevOpsProject.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledCollapse } from 'reactstrap';

class CreateDevOpsProject extends React.Component {
    render() {
        /* jshint ignore:start */
        return (
            <main className="body">
                <Link to="">
                    <FontAwesomeIcon icon="chevron-up" size="3x" />
                </Link>

                <div className="wallpaper">
                    <div className="container">
                        <header>
                            <h1>Create DevOps Project</h1>
                        </header>
                        <div className="row left-align">
                            <ol>
                                <li>
                                    Please ensure you have a team access package. If you don't,
                                    click{' '}
                                    <a
                                        href="https://jira.vipps.io/servicedesk/customer/portal/12/create/276"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        this link
                                    </a>{' '}
                                    to the Jira template and complete the request. Set <b>Type of request</b> to <i>Nusse - add DevOps/K8S Namespace to access package</i>.
                                </li>
                                <li>
                                    Visit{' '}
                                    <a
                                        href="https://vipps.visualstudio.com/Cloud-Platform-Self-Service/_build?definitionId=1253"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        Azure DevOps dedicated project
                                    </a>
                                    . Log in with your account, and run the pipeline.
                                    <ul>
                                        <li>
                                            Use lower case letters as your{' '}
                                            <code>&lt;&lt;k8s-namespace&gt;&gt;</code>.
                                            <span role="img" aria-label="explains UID" id="k8s">
                                                ❓
                                            </span>
                                            <UncontrolledCollapse
                                                toggler="#k8s"
                                                className="collapse-tip"
                                            >
                                                <br />
                                                <b>Naming scheme for Kubernetes Namespace</b>
                                                <br />
                                                <ul>
                                                    <li>
                                                        Cannot be prefixed with kube-, since it is
                                                        reserved for Kubernetes system namespaces{' '}
                                                    </li>
                                                    <li>Start with an alphanumeric character </li>
                                                    <li>End with an alphanumeric character </li>
                                                    <li>Contain at most 63 characters </li>
                                                    <li>
                                                        Contain only lowercase alphanumeric
                                                        characters or '-'{' '}
                                                    </li>
                                                </ul>
                                                <br />
                                            </UncontrolledCollapse>
                                        </li>
                                        <li>
                                            Use your team name or project in camel case as your{' '}
                                            <code>&lt;&lt;devopsProjectName&gt;&gt;</code>.
                                            <span role="img" aria-label="explains UID" id="devops">
                                                ❓
                                            </span>
                                            <UncontrolledCollapse
                                                toggler="#devops"
                                                className="collapse-tip"
                                            >
                                                <br />
                                                <b>Naming scheme for Azure DevOps Project</b>
                                                <br />
                                                <ul>
                                                    <li>
                                                        Can't contain special characters, such as /
                                                        : \ ~ &amp; % ; @ &apos; &quot; ? &lt; &gt;
                                                        | # $ * &#125; &#123; , + = [ ]{' '}
                                                    </li>
                                                    <li>Can't begin with an underscore </li>
                                                    <li>Can't begin or end with a period </li>
                                                    <li>Must be 64 or fewer characters </li>
                                                </ul>
                                                <br />
                                            </UncontrolledCollapse>{' '}
                                        </li>
                                    </ul>
                                    Make sure the build is successful. If the build is failing,
                                    message <code>#platform-support</code>.
                                </li>
                                <li>
                                    On a successful run of the pipeline, a Slack message is sent to{' '}
                                    <a
                                        href="https://vippsas.slack.com/archives/C031NB6E0QJ"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        <code>#link-access-package-to-devops-project</code>
                                    </a>{' '}
                                    channel so the Identity &amp; Access Management team can provide
                                    you access to your project and namespace. If you have questions
                                    about access, please contact <code>#proj-skohorn</code>.
                                    <br />
                                    Further information about the pipeline can be found in the{' '}
                                    <a
                                        href="https://vipps.visualstudio.com/Cloud-Platform-Self-Service/_git/Cloud-Platform-Self-Service"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        README here
                                    </a>
                                    .
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default CreateDevOpsProject;
