import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import AadCallback from './AadCallback';
import CreateCronJob from './views/CreateCronJob';
import CreateCronJobRelease from './views/CreateCronJobRelease';
import UID from './views/UID';
import FlowFive from './views/FlowFive';
import FlowOne from './views/FlowOne';
import FlowThree from './views/FlowThree';
import FlowTwo from './views/FlowTwo';
import FlowZero from './views/FlowZero';
import NetworkInformation from './views/NetworkInformation';
import React from 'react';
import Start from './views/Start';
import ViewNetworks from './views/ViewNetworks';

import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faLeaf,
} from '@fortawesome/free-solid-svg-icons';
import CreateDevOpsProject from './views/CreateDevOpsProject';

library.add(faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faLeaf);

/* jshint ignore:start */
function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/start/aad-callback" component={AadCallback} />
                <Route exact path="/start/build-and-release" component={Start} />
                <Route exact path="/start/create-identity" component={Start} />
                <Route exact path="/start/cron-job-release" component={Start} />
                <Route exact path="/start/cron-job" component={Start} />
                <Route exact path="/start/devops-project" component={Start} />
                <Route exact path="/start/finish" component={Start} />
                <Route exact path="/start/run-parameters" component={Start} />
                <Route exact path="/start/run-parameters/UID" component={Start} />
                <Route exact path="/start/security-pipeline" component={Start} />
                <Route exact path="/start/vnets" component={ViewNetworks} />
                <Route exact path="/start/vnets/detailed" component={NetworkInformation} />
                <Route exact path="/" component={Start} />
            </Switch>
        </Router>
    );
}
/* jshint ignore:end */
export default App;
