import { PublicClientApplication } from '@azure/msal-browser';
import { clearStoredPath, getStoredPath, storeCurrentPath } from './LocalRedirectUrlStorage';

const msalConfig = {
    auth: {
        clientId: 'b597b141-3347-4656-9d33-0bfcfa47565d',
        authority: 'https://login.microsoftonline.com/805bc25d-8e64-4ed6-8d24-3883c9068c5a/',
        knownAuthorities: [
            'https://login.microsoftonline.com/805bc25d-8e64-4ed6-8d24-3883c9068c5a/',
        ],
        redirectUri: `${window.location.origin}/start/aad-callback`,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

var app;
var notInBuild = process.env.NODE_ENV !== 'production' || navigator.userAgent !== 'ReactSnap';

if (notInBuild) {
    if (!window.sessionStorage.getItem('NusseToken')) {
        app = new PublicClientApplication(msalConfig);

        const accounts = app.getAllAccounts();
        if (accounts.length > 0) {
            app.setActiveAccount(accounts[0]);
        }

        app.addEventCallback(
            (event) => {
                // set active account after redirect
                if (event.payload && event.payload.account) {
                    const account = event.payload.account;
                    app.setActiveAccount(account);
                }
            },
            (error) => {
                console.log(`error: ${error}`);
            }
        );

        app.handleRedirectPromise()
            .then((tokenResponse) => {
                // Check if the tokenResponse is null
                // If the tokenResponse === null, you are not coming back from an auth redirect.
                // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
                if (tokenResponse !== null) {
                    var token = tokenResponse.accessToken;
                    window.sessionStorage.setItem('NusseToken', token);
                    var url = getStoredPath();
                    if (url) {
                        window.open(url, '_self');
                    }
                } else {
                    const request = {
                        scopes: ['b597b141-3347-4656-9d33-0bfcfa47565d/api'],
                        account: accounts[0],
                    };
                    app.acquireTokenRedirect(request);
                }
            })
            .catch((error) => {
                console.log(`error: ${error}`);
            });
    }
} else {
    app = {};
}

var fakeGetUser = function getFakeUser() {
    return {};
};
var getRealUser = () => app.getAllAccounts()[0];

export var getUser = notInBuild ? getRealUser : fakeGetUser;
