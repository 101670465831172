import 'bootstrap/dist/css/bootstrap.min.css';
import NewPipeline from './NewPipeline';
import React from 'react';
import FlowNavigation from '../components/FlowNavigation';

class FlowTwo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identity: '',
            docker: {
                name: '',
            },
            gitServiceConnection: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const identity = params.get('identity') || this.state.identity;
        const name = params.get('name') || this.state.docker.name;
        const gitServiceConnection = params.get('gitsc') || this.state.gitServiceConnection;
        this.setState({
            docker: { name: name },
            gitServiceConnection: gitServiceConnection,
            identity: identity,
        });
    }

    handleChange(e) {
        this.setState(e);
    }

    render() {
        var params = [];
        if (this.state.identity !== '') {
            params.push(`identity=${this.state.identity}`);
        }
        if (this.state.docker.name !== '') {
            params.push(`name=${this.state.docker.name}`);
        }
        if (this.state.gitServiceConnection !== '') {
            params.push(`gitsc=${this.state.gitServiceConnection}`);
        }
        var queryParams = (params.length !== 0 ? '?' : '') + params.join('&');

        /* jshint ignore:start */
        return (
        <div>
            <FlowNavigation 
                previous="/start/run-parameters"
                next={`/start/security-pipeline${queryParams}`}
                currentStep={3}
                numberOfSteps={5}
            ></FlowNavigation>
            <main className="body">
                <NewPipeline location={this.props.location} onChange={this.handleChange} />
            </main>
        </div>
        );
        /* jshint ignore:end */
    }
}

export default FlowTwo;
