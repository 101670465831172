/* eslint-disable no-alert */
import './NewValuesYaml.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, ModalHeader, UncontrolledCollapse } from 'reactstrap';
import Env from './Env';
import Probe from './Probe';
import React from 'react';
import Resources from './Resources';
import { Heading1 } from '@vippsno/ds-typography';
import { Button, ButtonGroup } from '@vippsno/ds-button';
import { AddIcon, MinusIcon } from '@vippsno/ds-icon';
import { link } from '@vippsno/ds-typography';
import { marginBottomMedium, marginTopMedium } from '@vippsno/ds-spacing';

const defaultLimits = {
    cpu: '1.',
    cpuUnit: '',
    memory: '120',
    memoryUnit: 'Mi',
};

const defaultRequests = {
    cpu: '.1',
    cpuUnit: '',
    memory: '70',
    memoryUnit: 'Mi',
};

const defaultEnvs = Array.from({ length: 10 }, (_, _i) => ({
    default: '',
    dr: '',
    index: 0,
    isOpen: false,
    mt: '',
    prod: '',
    uat: '',
    vName: '',
}));

const defaultRunAsUserId = 1000;

const defaultAutoScaling = {
    cpu: false,
    memory: false,
};

const defaultLivenessProbe = {
    initialDelaySeconds: 10,
    path: '/health',
    pollInterval: 10,
    port: 8080,
    protocol: 'http',
    timeoutSeconds: 2,
};

const defaultReadinessProbe = {
    initialDelaySeconds: 10,
    path: '',
    pollInterval: 10,
    port: 8080,
    protocol: 'http',
    timeoutSeconds: 2,
};

const defaultService = {
    port: 80,
};

const defaultIngress = {
    enabled: true,
    proxyIngressEnabled: false,
    port: 8080,
    path: '',
    extendedBodySize: false,
};

const defaultSideCar = {
    name: '',
    entrypoint: '',
    docker: {
        registry: 'acrvippsakscmn.azurecr.io',
        repository: 'vipps',
        image: '',
        tag: '',
    },
    limits: defaultLimits,
    requests: defaultRequests,
};

const defaultPrometheus = {
    enabled: true,
    path: '/metrics',
    port: 9991,
};

class NewValuesYaml extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            autoScaling: defaultAutoScaling,
            entrypoint: '',
            envs: defaultEnvs.map((env) => ({ ...env })),
            hpaIsOpen: false,
            ingress: defaultIngress,
            limits: defaultLimits,
            livenessProbe: defaultLivenessProbe,
            maxConn: 16,
            name: '',
            owner: '',
            params: '',
            prometheus: defaultPrometheus,
            readinessProbe: defaultReadinessProbe,
            replicas: 2,
            requests: defaultRequests,
            runAsUserId: defaultRunAsUserId,
            service: defaultService,
            sideCar: defaultSideCar,
            size: 1,
        };

        this.copy = this.copy.bind(this);
        this.download = this.download.bind(this);
        this.handleIngressEnabled = this.handleIngressEnabled.bind(this);
        this.handleProxyIngressEnabled = this.handleProxyIngressEnabled.bind(this);
        this.handleExtendedBodySize = this.handleExtendedBodySize.bind(this);
        this.handleAddEnv = this.handleAddEnv.bind(this);
        this.handleRemoveEnv = this.handleRemoveEnv.bind(this);
        this.handleAutoScalingCpu = this.handleAutoScalingCpu.bind(this);
        this.handleAutoScalingMemory = this.handleAutoScalingMemory.bind(this);
        this.handleIngressPort = this.handleIngressPort.bind(this);
        this.handleEnv = this.handleEnv.bind(this);
        this.handleLimits = this.handleLimits.bind(this);
        this.handleLiveness = this.handleLiveness.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleEntrypoint = this.handleEntrypoint.bind(this);
        this.handleOwner = this.handleOwner.bind(this);
        this.handleIngressPath = this.handleIngressPath.bind(this);
        this.handlePort = this.handlePort.bind(this);
        this.handlePromEnabled = this.handlePromEnabled.bind(this);
        this.handlePromPath = this.handlePromPath.bind(this);
        this.handlePromPort = this.handlePromPort.bind(this);
        this.handleReadiness = this.handleReadiness.bind(this);
        this.handleReplicas = this.handleReplicas.bind(this);
        this.handleRequests = this.handleRequests.bind(this);
        this.handleRunAsUserId = this.handleRunAsUserId.bind(this);
        this.handleSideCarEntrypoint = this.handleSideCarEntrypoint.bind(this);
        this.handleSideCarImage = this.handleSideCarImage.bind(this);
        this.handleSideCarName = this.handleSideCarName.bind(this);
        this.handleSideCarRegistry = this.handleSideCarRegistry.bind(this);
        this.handleSideCarRepository = this.handleSideCarRepository.bind(this);
        this.handleSideCarTag = this.handleSideCarTag.bind(this);
        this.handleSideCarRequests = this.handleSideCarRequests.bind(this);
        this.handleSideCarLimits = this.handleSideCarLimits.bind(this);
        this.toggleHPA = this.toggleHPA.bind(this);
        this.refPre = React.createRef();
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const autoScaling = JSON.parse(params.get('autoscaling')) || this.state.autoScaling;
        const entrypoint = params.get('entrypoint') || this.state.entrypoint;
        const envs = JSON.parse(params.get('envs')) || this.state.envs;
        const ingress = JSON.parse(params.get('ingress')) || this.state.ingress;
        const limits = JSON.parse(params.get('limits')) || this.state.limits;
        const livenessProbe = JSON.parse(params.get('livenessprobe')) || this.state.livenessProbe;
        const name = params.get('name') || this.state.name;
        const owner = params.get('owner') || this.state.owner;
        const prometheus = JSON.parse(params.get('prometheus')) || this.state.prometheus;
        const readinessProbe =
            JSON.parse(params.get('readinessprobe')) || this.state.readinessProbe;
        const replicas = parseInt(params.get('replicas'), 10) || this.state.replicas;
        const requests = JSON.parse(params.get('requests')) || this.state.requests;
        const runAsUserId = parseInt(params.get('runasuserid'), 10) || this.state.runAsUserId;
        const sideCar = JSON.parse(params.get('sidecar')) || this.state.sideCar;
        this.setState({
            autoScaling: autoScaling,
            entrypoint: entrypoint,
            envs: envs,
            ingress: ingress,
            limits: limits,
            livenessProbe: livenessProbe,
            name: name,
            owner: owner,
            params: this.createParams(this.state),
            prometheus: prometheus,
            readinessProbe: readinessProbe,
            replicas: replicas,
            requests: requests,
            runAsUserId: runAsUserId,
            sideCar: sideCar,
        });
    }

    equalOrNull(object1, object2) {
        return object1 === null || JSON.stringify(object1) === JSON.stringify(object2);
    }

    createParams(newPipelineState) {
        var paramValues = [];

        if (!this.equalOrNull(newPipelineState.autoScaling, defaultAutoScaling)) {
            paramValues.push(
                `autoscaling=${encodeURIComponent(JSON.stringify(newPipelineState.autoScaling))}`
            );
        }

        if (newPipelineState.entrypoint !== '') {
            paramValues.push(`entrypoint=${encodeURIComponent(newPipelineState.entrypoint)}`);
        }

        if (!this.equalOrNull(newPipelineState.envs, defaultEnvs)) {
            paramValues.push(`envs=${encodeURIComponent(JSON.stringify(newPipelineState.envs))}`);
        }

        if (!this.equalOrNull(newPipelineState.ingress, defaultIngress)) {
            paramValues.push(
                `ingress=${encodeURIComponent(JSON.stringify(newPipelineState.ingress))}`
            );
        }

        if (!this.equalOrNull(newPipelineState.limits, defaultLimits)) {
            paramValues.push(
                `limits=${encodeURIComponent(JSON.stringify(newPipelineState.limits))}`
            );
        }

        if (!this.equalOrNull(newPipelineState.livenessProbe, defaultLivenessProbe)) {
            paramValues.push(
                `livenessprobe=${encodeURIComponent(
                    JSON.stringify(newPipelineState.livenessProbe)
                )}`
            );
        }

        if (newPipelineState.name !== '') {
            paramValues.push(`name=${encodeURIComponent(newPipelineState.name)}`);
        }

        if (newPipelineState.owner !== '') {
            paramValues.push(`owner=${encodeURIComponent(newPipelineState.owner)}`);
        }

        if (!this.equalOrNull(newPipelineState.prometheus, defaultPrometheus)) {
            paramValues.push(
                `prometheus=${encodeURIComponent(JSON.stringify(newPipelineState.prometheus))}`
            );
        }

        if (!this.equalOrNull(newPipelineState.readinessProbe, defaultReadinessProbe)) {
            paramValues.push(
                `readinessprobe=${encodeURIComponent(
                    JSON.stringify(newPipelineState.readinessProbe)
                )}`
            );
        }

        if (newPipelineState.replicas !== 2) {
            paramValues.push(`replicas=${newPipelineState.replicas}`);
        }

        if (!this.equalOrNull(newPipelineState.requests, defaultRequests)) {
            paramValues.push(
                `requests=${encodeURIComponent(JSON.stringify(newPipelineState.requests))}`
            );
        }

        if (newPipelineState.runAsUserId !== defaultRunAsUserId) {
            paramValues.push(`runasuserid=${parseInt(newPipelineState.runAsUserId, 0)}`);
        }

        if (!this.equalOrNull(newPipelineState.service, defaultService)) {
            paramValues.push(
                `service=${encodeURIComponent(JSON.stringify(newPipelineState.service))}`
            );
        }

        if (!this.equalOrNull(newPipelineState.sideCar, defaultSideCar)) {
            paramValues.push(
                `sidecar=${encodeURIComponent(JSON.stringify(newPipelineState.sideCar))}`
            );
        }

        const params = (paramValues.length !== 0 ? '?' : '') + paramValues.join('&');
        return params;
    }

    getState(state) {
        return {
            ingress: state.ingress,
            name: state.name,
            runAsUserId: state.runAsUserId,
        };
    }

    handleName(e) {
        this.setState(
            (s) => ({
                name: e.target.value,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
                this.props.onChange(this.getState(this.state));
            }
        );
    }

    handleEntrypoint(e) {
        this.setState({ entrypoint: e.target.value }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleOwner(e) {
        this.setState({ owner: e.target.value }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handlePort(e) {
        this.setState(
            (s) => ({
                ...s,
                service: {
                    ...s.service,
                    port: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handlePromEnabled(e) {
        this.setState(
            (s) => ({
                prometheus: {
                    ...s.prometheus,
                    enabled: !s.prometheus.enabled,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handlePromPath(e) {
        this.setState(
            (s) => ({
                prometheus: {
                    ...s.prometheus,
                    path: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handlePromPort(e) {
        this.setState(
            (s) => ({
                prometheus: {
                    ...s.prometheus,
                    port: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleIngressPort(e) {
        this.setState(
            (s) => ({
                ingress: {
                    ...s.ingress,
                    port: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleIngressPath(e) {
        this.setState(
            (s) => ({
                ...s,
                ingress: {
                    ...s.ingress,
                    path: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
                this.props.onChange(this.getState(this.state));
            }
        );
    }

    handleIngressEnabled(e) {
        this.setState(
            (s) => ({
                ...s,
                ingress: {
                    ...s.ingress,
                    enabled: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleProxyIngressEnabled(e) {
        this.setState(
            (s) => ({
                ...s,
                ingress: {
                    ...s.ingress,
                    proxyIngressEnabled: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleExtendedBodySize(e) {
        this.setState(
            (s) => ({
                ...s,
                ingress: {
                    ...s.ingress,
                    extendedBodySize: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleReplicas(e) {
        this.setState(
            {
                replicas: e.target.value,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleAutoScalingCpu(e) {
        this.setState(
            (s) => ({
                ...s,
                autoScaling: {
                    ...s.autoScaling,
                    cpu: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleAutoScalingMemory(e) {
        this.setState(
            (s) => ({
                ...s,
                autoScaling: {
                    ...s.autoScaling,
                    memory: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    copy(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert('values.yaml copied to clipboard');
            })
            .catch((err) => {
                alert('Error in copying values.yaml to clipboard: ', err);
            });
    }

    download(text) {
        const blob = new Blob([text], { type: 'text/plain' });
        return URL.createObjectURL(blob);
    }

    handleRequests(e) {
        this.setState({ requests: e }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleRunAsUserId(e) {
        this.setState({ runAsUserId: parseInt(e.target.value, 10) }, () => {
            this.setState({ params: this.createParams(this.state) });
            this.props.onChange(this.getState(this.state));
        });
    }

    handleSideCarEntrypoint(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    entrypoint: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarName(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    name: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarRegistry(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    docker: {
                        ...s.sideCar.docker,
                        registry: e.target.value,
                    },
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }
    handleSideCarImage(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    docker: {
                        ...s.sideCar.docker,
                        image: e.target.value,
                    },
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarRepository(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    docker: {
                        ...s.sideCar.docker,
                        repository: e.target.value,
                    },
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarTag(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    docker: {
                        ...s.sideCar.docker,
                        tag: e.target.value,
                    },
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarRequests(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    requests: e,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleSideCarLimits(e) {
        this.setState(
            (s) => ({
                sideCar: {
                    ...s.sideCar,
                    limits: e,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    toggleHPA(_) {
        this.setState({
            hpaIsOpen: !this.state.hpaIsOpen,
        });
    }

    handleAddEnv(_) {
        var envs = this.state.envs;
        for (var i = 0; i < envs.length; i++) {
            if (envs[i].isOpen === false) {
                envs[i].isOpen = true;
                break;
            }
        }

        this.setState({ envs: envs }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleRemoveEnv(_) {
        var envs = this.state.envs;
        for (var i = envs.length - 1; i >= 0; i--) {
            if (envs[i].isOpen === true) {
                envs[i].isOpen = false;
                envs[i].default = '';
                envs[i].uat = '';
                envs[i].mt = '';
                envs[i].prod = '';
                envs[i].dr = '';
                envs[i].vName = '';
                break;
            }
        }

        this.setState({ envs: envs }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleLimits(e) {
        this.setState({ limits: e }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleLiveness(e) {
        this.setState({ livenessProbe: e }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleReadiness(e) {
        this.setState({ readinessProbe: e }, () => {
            this.setState({ params: this.createParams(this.state) });
        });
    }

    handleEnv(e) {
        this.setState(
            (state) => {
                const envs = state.envs;
                envs[e.index] = e;
                return {
                    envs,
                };
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }


    serializeYamlStringLiteral(raw) {
        raw = raw.replace(/\\/g, '\\\\');
        raw = raw.replace(/\n/g, '\\n');
        raw = raw.replace(/"/g, `\\"`);
        return `"${raw}"`;
    }

    createValuesYaml(state) {
        const hasService = true; // (state.service.port !== 80);
        const service = `
service:
  port: ${state.service.port}
`;

        var ports = `containerPorts:\n  - name: application\n    containerPort: ${state.ingress.port}\n`;
        if (state.prometheus.enabled) {
            ports += `  - name: http-prom\n    containerPort: ${state.prometheus.port}\n`;
        }

        const hasAutoScaling = state.autoScaling.cpu || state.autoScaling.memory;
        const autoScaling = `autoScaling:\n${state.autoScaling.cpu ? '  cpu: true\n' : ''}${
            state.autoScaling.memory ? '  memory: true\n' : ''
        }`;

        const replicas = `replicas: ${state.replicas}\n`;

        const hasLivenessProbe = state.livenessProbe.path !== '';
        const livenessProbe = `livenessProbe:\n${
            state.livenessProbe.initialDelaySeconds !== 10
                ? `  initialDelaySeconds: ${state.livenessProbe.initialDelaySeconds}\n`
                : ''
        }  path: ${state.livenessProbe.path}\n${
            state.livenessProbe.pollInterval !== 10
                ? `  periodSeconds: ${state.livenessProbe.pollInterval}\n`
                : ''
        }${state.livenessProbe.port !== 8080 ? `  port: ${state.livenessProbe.port}\n` : ''}${
            state.livenessProbe.protocol !== 'http'
                ? `  protocol: ${state.livenessProbe.protocol}\n`
                : ''
        }${
            state.livenessProbe.timeoutSeconds !== 2
                ? `  timeoutSeconds: ${state.livenessProbe.timeoutSeconds}\n`
                : ''
        }`;

        const hasReadinessProbe = state.readinessProbe.path !== '';
        const readinessProbe = `readinessProbe:\n${
            state.readinessProbe.initialDelaySeconds !== 10
                ? `  initialDelaySeconds: ${state.readinessProbe.initialDelaySeconds}\n`
                : ''
        }  path: ${state.readinessProbe.path}\n${
            state.readinessProbe.pollInterval !== 10
                ? `  periodSeconds: ${state.readinessProbe.pollInterval}\n`
                : ''
        }${state.readinessProbe.port !== 8080 ? `  port: ${state.readinessProbe.port}\n` : ''}${
            state.readinessProbe.protocol !== 'http'
                ? `  protocol: ${state.readinessProbe.protocol}\n`
                : ''
        }${
            state.readinessProbe.timeoutSeconds !== 2
                ? `  timeoutSeconds: ${state.readinessProbe.timeoutSeconds}\n`
                : ''
        }`;
        const securityContext =
            this.state.runAsUserId !== 0
                ? `securityContext:
  runAsUser: ${this.state.runAsUserId}
`
                : '';

        const hasSideCar = state.sideCar.name !== '';
        const sideCarRequests =
            `  resourcesRequestsCpu: ${state.sideCar.requests.cpu}${state.sideCar.requests.cpuUnit}\n` +
            `  resourcesRequestsMemory: ${state.sideCar.requests.memory}${state.sideCar.requests.memoryUnit}\n`;

        const sideCarLimits =
            `  resourcesLimitsCpu: ${state.sideCar.limits.cpu}${state.sideCar.limits.cpuUnit}\n` +
            `  resourcesLimitsMemory: ${state.sideCar.limits.memory}${state.sideCar.limits.memoryUnit}\n`;

        const sideCar =
            `sideCar:\n${
                state.sideCar.entrypoint !== '' ? `  entrypoint: ${state.sideCar.entrypoint}\n` : ''
            }${
                state.sideCar.image !== '' ? `  image: ${state.sideCar.docker.image}\n` : ''
            }  name: ${state.sideCar.name}\n` +
            `  docker:\n` +
            `    registry: ${state.sideCar.docker.registry}\n` +
            `    repository: ${state.sideCar.docker.repository}\n${
                state.sideCar.docker.tag !== '' ? `    tag: ${state.sideCar.docker.tag}\n` : ''
            }${sideCarLimits}${sideCarRequests}`;

        var hasEnvs = false;
        var envs = 'envs:\n';
        for (const ev of state.envs) {
            hasEnvs |= ev.isOpen && ev.vName !== '';
            if (ev.vName !== '') {
                envs += `  ${ev.vName}:\n${
                    ev.default !== '' ? `    _default: ${ev.default}\n` : ''
                }${ev.uat !== '' ? `    uat: ${this.serializeYamlStringLiteral(ev.uat)}\n` : ''}${
                    ev.mt !== '' ? `    mt: ${this.serializeYamlStringLiteral(ev.mt)}\n` : ''
                }${ev.prod !== '' ? `    prod: ${this.serializeYamlStringLiteral(ev.prod)}\n` : ''}${
                    ev.dr !== '' ? `    dr: ${this.serializeYamlStringLiteral(ev.dr)}\n` : ''
                }`;
            }
        }
        const synthenticPath = `/${state.name}`;
        const text = `# Generated from
# ${window.location.protocol}//${window.location.host}${window.location.pathname}${
            this.state.params
        }
# You can allways visit this link to download this values.yaml.
app:
  name: ${state.name}
${ports}${securityContext}ingress:\n${state.ingress.enabled ? '  enabled: true\n' : ''}${
            state.ingress.proxyIngressEnabled ? '  proxyIngressEnabled: true\n' : ''
        }  path: ${state.ingress.path || synthenticPath}\n${
            state.ingress.extendedBodySize ? '  extendedBodySize: true\n' : ''
        }${hasLivenessProbe ? livenessProbe : ''}linkerd:
  enabled: true
owner: ${state.owner}
${state.entrypoint !== '' ? `entrypoint: ${state.entrypoint}` : ''}
${
    state.prometheus.enabled
        ? `prometheus:\n  path: ${state.prometheus.path}\n  portName: http-prom\n`
        : ''
}${replicas}${hasAutoScaling ? autoScaling : ''}${hasReadinessProbe ? readinessProbe : ''}
resourcesLimitsCpu: ${state.limits.cpu}${state.limits.cpuUnit}
resourcesLimitsMemory: ${state.limits.memory}${state.limits.memoryUnit}
resourcesRequestsCpu: ${state.requests.cpu}${state.requests.cpuUnit}
resourcesRequestsMemory: ${state.requests.memory}${state.requests.memoryUnit}
${hasService ? service : ''}${hasSideCar ? sideCar : ''}${hasEnvs ? envs : ''}
`;
        return text;
    }

    render() {
        const valuesYaml = this.createValuesYaml(this.state);
        /* jshint ignore:start */
        return (
            <main className="body">
                <div className="container">
                    <header>
                        <Heading1>Application Run Parameters</Heading1>
                        <p>
                            <b>
                                This page helps you create <code>values.yaml</code>.
                            </b>
                            <br />
                            This file is required to deploy an application to kubernetes. In file
                            describes how your application runs in the environments. In values.yaml
                            you will have to define how much resources your application needs,{' '}
                            <a href="https://docs.microsoft.com/en-us/azure/active-directory/managed-identities-azure-resources/overview" rel="noreferrer noopener" target="_blank" className={`${link({inline: true})}`}>
                                managed identity
                            </a>{' '}
                            (we will get back to managed identity on the next pages), and what ports
                            your application runs on.
                        </p>
                        <p>
                            Once you are done you will get a new file values.yaml that you must put
                            in a directory deploy on your git root.
                        </p>
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="application-name">application name</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="text"
                                name="application-name"
                                data-testid="application-name"
                                defaultValue={this.state.name}
                                placeholder="my-application"
                                pattern="([a-z]|æ|ø|å|[0-9]|-)+"
                                required={true}
                                onChange={this.handleName}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="entrypoint">entrypoint</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="text"
                                name="entrypoint"
                                data-testid="entrypoint"
                                defaultValue={this.state.entrypoint}
                                placeholder="/bin/app"
                                pattern="([a-z]|æ|ø|å|[0-9]|-|\/)+"
                                onChange={this.handleEntrypoint}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="owner">owner</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="text"
                                name="owner"
                                data-testid="owner"
                                defaultValue={this.state.owner}
                                placeholder="team-netthandel"
                                pattern="([a-z]|æ|ø|å|[0-9]|-)+"
                                onChange={this.handleOwner}
                            ></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="container-port">docker container port</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="number"
                                name="container-port"
                                data-testid="container-port"
                                defaultValue={this.state.ingress.port}
                                onChange={this.handleIngressPort}
                                min="80"
                                max="65535"
                                step="1"
                            ></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="port">internet port</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="number"
                                name="port"
                                data-testid="port"
                                defaultValue={this.state.service.port}
                                min="80"
                                max="65535"
                                step="1"
                                required
                                onChange={this.handlePort}
                            ></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label>path (used by nginx ingress)</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="text"
                                name="path"
                                data-testid="path"
                                defaultValue={this.state.service.path}
                                placeholder="/my-application"
                                pattern="\/([a-z]|[0-9]|-)*"
                                onChange={this.handleIngressPath}
                                required={true}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="uid">Running docker with user ID (UID)</label>
                        </div>
                        <div className="col-md-3">
                            <input
                                type="number"
                                name="uid"
                                data-testid="uid"
                                defaultValue={this.state.runAsUserId}
                                onChange={this.handleRunAsUserId}
                                min="0"
                                max="65534"
                                step="1000"
                            ></input>
                            <span style={{ padding: '1em' }}>
                                <span
                                    style={{
                                        color: 'red',
                                        fontWeight: 600,
                                        display: this.state.runAsUserId === 0 ? '' : 'none',
                                    }}
                                >
                                    Run as root
                                </span>
                                <span
                                    style={{
                                        color: 'red',
                                        fontWeight: 600,
                                        display:
                                            this.state.runAsUserId >= 1 &&
                                            this.state.runAsUserId <= 99
                                                ? ''
                                                : 'none',
                                    }}
                                >
                                    reserved by linux conventions for other predefined accounts.
                                </span>
                                <span
                                    style={{
                                        color: 'red',
                                        fontWeight: 600,
                                        display:
                                            this.state.runAsUserId >= 100 &&
                                            this.state.runAsUserId <= 999
                                                ? ''
                                                : 'none',
                                    }}
                                >
                                    reserved by linux conventions for administrative and system
                                    accounts/groups.
                                </span>
                                <span
                                    style={{
                                        color: 'red',
                                        fontWeight: 600,
                                        display: this.state.runAsUserId > 10000 ? '' : 'none',
                                    }}
                                >
                                    reserved by linux conventions for user accounts.
                                </span>
                            </span>
                        </div>
                        <div className="col-md-6">
                            <span role="img" aria-label="explains UID" id="UID">
                                ❓
                            </span>
                            <UncontrolledCollapse toggler="#UID">
                                <p>
                                    Feature flag for enabling securityContext for pods and
                                    containers running with out root.
                                </p>
                                <p>
                                    You must mention <code>runAsUser: UID</code> where UID is a non
                                    zero integer represent the pre-configured Linux user as part of
                                    image build process. Also assume that the user is correctly
                                    tagged to a non root group.
                                </p>
                                <p>
                                    For an ubuntu base image, you can mention{' '}
                                    <code>RUN adduser -D javauser</code> and <code>USER 1000</code>{' '}
                                    on the next line in the DockerFile.
                                </p>
                            </UncontrolledCollapse>
                        </div>
                    </div>
                    <h2>Scaling</h2>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="replicas">replicas</label>
                        </div>
                        <div className="col-md-3">
                            <input
                                type="number"
                                name="replicas"
                                data-testid="replicas"
                                defaultValue={this.state.replicas}
                                min="1"
                                max="24"
                                step="1"
                                onChange={this.handleReplicas}
                            ></input>
                        </div>
                        <div className="col-md-6">
                            <span
                                style={{
                                    margin: '1em',
                                    display:
                                        this.state.autoScaling.cpu || this.state.autoScaling.memory
                                            ? ''
                                            : 'none',
                                }}
                            >
                                replicas per cluster min: 1 max:{' '}
                                {Math.min(24, this.state.replicas * 2)}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="cpu">
                                <a href="https://kubernetes.io/docs/tasks/run-application/horizontal-pod-autoscale/" className={`${link({inline: true})}`} rel="noreferrer noopener" target="_blank">
                                    scale horizontal
                                </a>{' '}
                                based on cpu
                            </label>
                        </div>
                        <div className="col-md-3">
                            <input
                                type="checkbox"
                                name="cpu"
                                data-testid="cpu"
                                checked={this.state.autoScaling.cpu}
                                onChange={this.handleAutoScalingCpu}
                            ></input>
                        </div>
                        <div className="col-md-6">
                            <span onClick={this.toggleHPA}>More details...</span>
                            <Modal toggle={this.toggleHPA} isOpen={this.state.hpaIsOpen}>
                                <ModalHeader toggle={this.toggleHPA}>
                                    Horizontal Scaling
                                </ModalHeader>
                                <ModalBody>
                                    Vipps Plattform team recommends to scale horizontal if the
                                    application is not part of a payment flow. Horizontal scaling is
                                    based on{' '}
                                    <a href="https://kubernetes.io/docs/tasks/run-application/horizontal-pod-autoscale/" className={`${link({inline: true})}`} rel="noreferrer noopener" target="_blank">
                                        Kubernetes Horizontal Pod Autoscaler
                                    </a>
                                    . Se scale up and down and down when you reach 70% of your
                                    Requests CPU or Requests Memory or both (depending on what you
                                    decide to scale on). See{' '}
                                    <a
                                        href="https://github.com/vippsas/k8s-app-conf/blob/master/charts/vipps-service/templates/hpa.yaml"
                                        className={`${link({inline: true})}` + " explainer"} rel="noreferrer noopener" target="_blank"
                                    >
                                        Vipps Implementation details
                                    </a>
                                    <br />
                                    <b>
                                        Never use horizontal scaling in a payment flow. We believe
                                        your application will not start fast enough to handle
                                        important events such as fund raiser programs or national
                                        marketing campaigns.
                                    </b>
                                </ModalBody>
                            </Modal>
                            <span role="img" aria-label="explains horizonal scaling">
                                ❓
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="memory">
                                <a href="https://kubernetes.io/docs/tasks/run-application/horizontal-pod-autoscale/" className={`${link({inline: true})}`} rel="noreferrer noopener" target="_blank">
                                    scale horizontal
                                </a>{' '}
                                based on memory
                            </label>
                        </div>
                        <div className="col-md-9">
                            <input
                                type="checkbox"
                                name="memory"
                                data-testid="memory"
                                checked={this.state.autoScaling.memory}
                                onChange={this.handleAutoScalingMemory}
                            ></input>
                        </div>
                    </div>
                    <Button type="button" id="probes" className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                        <FontAwesomeIcon icon="chevron-down" />&nbsp;
                        Health probes
                    </Button>
                    <UncontrolledCollapse toggler="#probes">
                        <div className="row">
                            <div className="col-md-3">
                                <h3>Liveness probe</h3>
                            </div>
                        </div>
                        <Probe
                            initialDelaySeconds={this.state.livenessProbe.initialDelaySeconds}
                            onChange={this.handleLiveness}
                            path={this.state.livenessProbe.path}
                            pollInterval={this.state.livenessProbe.pollInterval}
                            port={this.state.livenessProbe.port}
                            protocol={this.state.livenessProbe.protocol}
                            timeoutSeconds={this.state.livenessProbe.timeoutSeconds}
                        />
                        <div className="row">
                            <div className="col-md-3">
                                <h3>Readiness probe</h3>
                            </div>
                        </div>
                        <Probe
                            initialDelaySeconds={this.state.readinessProbe.initialDelaySeconds}
                            onChange={this.handleReadiness}
                            path={this.state.readinessProbe.path}
                            pollInterval={this.state.readinessProbe.pollInterval}
                            port={this.state.readinessProbe.port}
                            protocol={this.state.readinessProbe.protocol}
                            timeoutSeconds={this.state.readinessProbe.timeoutSeconds}
                        />
                    </UncontrolledCollapse>
                    <h3>Requests</h3>
                    <p>
                        <b>The resources your application use during startup.</b>
                        <br />
                    </p>
                    <p>
                        <FontAwesomeIcon icon="leaf" size="3x" color="green" />
                        <br />
                        We encourage you to help us act sustainable in the cloud and set the value
                        low. Requesting less than you actually use helps us minimise the resources
                        we reserve.
                        <br />
                    </p>
                    <Resources
                        showSizes={true}
                        cpu={this.state.requests.cpu}
                        cpuUnit={this.state.requests.cpuUnit}
                        memory={this.state.requests.memory}
                        memoryUnit={this.state.requests.memoryUnit}
                        onChange={this.handleRequests}
                        limits={false}
                    />
                    <Button type="button" id="pro-tip" className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                        <span role="img" aria-label="lightbulb">&nbsp;
                            💡
                        </span>
                        <FontAwesomeIcon icon="chevron-down" />&nbsp;
                        Pro tip requests
                    </Button>
                    <UncontrolledCollapse toggler="#pro-tip">
                        <b>
                            This is the average memory your application is using after running for
                            a day or two.
                        </b>
                        <p>
                            you can visit{' '}
                            <a href="https://vipps.grafana.net/d/6581e46e4e5c7ba40a07646395ef7b23/vce-pod-utilization?var-datasource=prometheus-uat" className={`${link({inline: true})}`} rel="noreferrer noopener" target="_blank">
                                grafana | Pod utilization
                            </a>{' '}
                            to get these numbers.
                        </p>
                        <p>
                            Is your application not yet hosted?
                            <br />
                            <br />
                            You can also test this with docker on your developer-PC.
                            <br />
                            <br />
                            For example with 120 megabyte memory and one tenth CPU
                            <br />
                            <code>
                                docker run -it --memory="120m" --cpus="0.1" &lt;my-application&gt;
                            </code>
                            <br />
                            <small>
                                <a href="https://docs.docker.com/config/containers/resource_constraints/" className={`${link({inline: true})}`} rel="noreferrer noopener" target="_blank">
                                    Docker: Runtime options with Memory, CPUs, and GPUs
                                </a>
                            </small>
                        </p>
                    </UncontrolledCollapse>
                    <br />
                    <h3>Limits</h3>
                    <p>
                        The absolute maximum amount of resources your application will need when
                        running.
                    </p>
                    <Resources
                        showSizes={true}
                        cpu={this.state.limits.cpu}
                        cpuUnit={this.state.limits.cpuUnit}
                        memory={this.state.limits.memory}
                        memoryUnit={this.state.limits.memoryUnit}
                        onChange={this.handleLimits}
                        limits={true}
                    />
                    <Button type="button" id="prom" className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                        <FontAwesomeIcon icon="chevron-down" />&nbsp;
                        Prometheus
                    </Button>
                    <UncontrolledCollapse toggler="#prom">
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="prom">enabled</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="checkbox"
                                    name="prom"
                                    data-testid="prom"
                                    checked={this.state.prometheus.enabled}
                                    onChange={this.handlePromEnabled}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="prom-path">path</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="prom-path"
                                    data-testid="prom-path"
                                    defaultValue={this.state.prometheus.path}
                                    onChange={this.handlePromPath}
                                ></input>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="prom-path">port</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="number"
                                    name="prom-patth"
                                    data-testid="prom-patth"
                                    defaultValue={this.state.prometheus.port}
                                    min="80"
                                    max="65535"
                                    onChange={this.handlePromPort}
                                ></input>
                            </div>
                        </div>
                    </UncontrolledCollapse>

                    <Button type="button" id="toggler" className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                        <FontAwesomeIcon icon="chevron-down" />&nbsp;
                        Kubernetes side car
                    </Button>
                    <UncontrolledCollapse toggler="toggler">
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-name">name</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-name"
                                    data-testid="sidecar-name"
                                    defaultValue={this.state.sideCar.vName}
                                    onChange={this.handleSideCarName}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-entrypoint">entrypoint</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-entrypoint"
                                    data-testid="sidecar-entrypoint"
                                    defaultValue={this.state.sideCar.entrypoint}
                                    placeholder="/app/bin"
                                    onChange={this.handleSideCarEntrypoint}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-image">image</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-image"
                                    data-testid="sidecar-image"
                                    defaultValue={this.state.sideCar.image}
                                    onChange={this.handleSideCarImage}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-docker-registry">registry</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-docker-registry"
                                    data-testid="sidecar-docker-registry"
                                    defaultValue={this.state.sideCar.docker.registry}
                                    onChange={this.handleSideCarRegistry}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-docker-repository">repository</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-docker-repository"
                                    data-testid="sidecar-docker-repository"
                                    defaultValue={this.state.sideCar.docker.repository}
                                    onChange={this.handleSideCarRepository}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="sidecar-docker-tag">tag</label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    name="sidecar-image"
                                    data-testid="sidecar-image"
                                    defaultValue={this.state.sideCar.docker.tag}
                                    placeholder="1.0.0"
                                    onChange={this.handleSideCarTag}
                                ></input>
                            </div>
                        </div>
                        <h3>Requests</h3>
                        <Resources
                            cpu={this.state.sideCar.requests.cpu}
                            cpuUnit={this.state.sideCar.requests.cpuUnit}
                            memory={this.state.sideCar.requests.memory}
                            memoryUnit={this.state.sideCar.requests.memoryUnit}
                            onChange={this.handleSideCarRequests}
                        />
                        <h3>Limits</h3>
                        <Resources
                            cpu={this.state.sideCar.limits.cpu}
                            cpuUnit={this.state.sideCar.limits.cpuUnit}
                            memory={this.state.sideCar.limits.memory}
                            memoryUnit={this.state.sideCar.limits.memoryUnit}
                            onChange={this.handleSideCarLimits}
                        />
                    </UncontrolledCollapse>

                    <Button type="button" id="nginx" className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                        <FontAwesomeIcon icon="chevron-down" />&nbsp;
                        Nginx
                    </Button>
                    <UncontrolledCollapse toggler="#nginx">
                        <p>Advanced nginx ingress options</p>
                        <div className="row">
                            <div className="col-md-3">
                                <label name="nginx-enabled" data-testid="nginx-enabled">
                                    enabled
                                </label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="checkbox"
                                    name="nginx-enabled"
                                    data-testid="nginx-enabled"
                                    checked={this.state.ingress.enabled}
                                    onChange={this.handleIngressEnabled}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label name="nginx-enabled" data-testid="nginx-enabled">
                                    Proxy ingress enabled
                                </label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="checkbox"
                                    name="nginx-enabled"
                                    data-testid="nginx-enabled"
                                    checked={this.state.ingress.proxyIngressEnabled}
                                    onChange={this.handleProxyIngressEnabled}
                                ></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label name="extended-body-size" data-testid="extended-body-size">
                                    Extended body size
                                </label>
                            </div>
                            <div className="col-md-9">
                                <input
                                    type="checkbox"
                                    name="extended-body-size"
                                    data-testid="extended-body-size"
                                    checked={this.state.ingress.extendedBodySize}
                                    onChange={this.handleExtendedBodySize}
                                ></input>
                            </div>
                        </div>
                    </UncontrolledCollapse>
                    <h4>Environment Variables</h4>
                    <ButtonGroup>
                        <Button size="small" icon={<AddIcon />} className="push" onClick={this.handleAddEnv}></Button>
                        <Button size="small" className="push" icon={<MinusIcon />} onClick={this.handleRemoveEnv}></Button>
                    </ButtonGroup>
                    <Env
                        isOpen={this.state.envs[0].isOpen}
                        index={0}
                        vName={this.state.envs[0].vName}
                        default={this.state.envs[0].default}
                        uat={this.state.envs[0].uat}
                        mt={this.state.envs[0].mt}
                        prod={this.state.envs[0].prod}
                        dr={this.state.envs[0].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[1].isOpen}
                        index={1}
                        vName={this.state.envs[1].vName}
                        default={this.state.envs[1].default}
                        uat={this.state.envs[1].uat}
                        mt={this.state.envs[1].mt}
                        prod={this.state.envs[1].prod}
                        dr={this.state.envs[1].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[2].isOpen}
                        index={2}
                        vName={this.state.envs[2].vName}
                        default={this.state.envs[2].default}
                        uat={this.state.envs[2].uat}
                        mt={this.state.envs[2].mt}
                        prod={this.state.envs[2].prod}
                        dr={this.state.envs[2].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[3].isOpen}
                        index={3}
                        vName={this.state.envs[3].vName}
                        default={this.state.envs[3].default}
                        uat={this.state.envs[3].uat}
                        mt={this.state.envs[3].mt}
                        prod={this.state.envs[3].prod}
                        dr={this.state.envs[3].dr}
                        onChange={this.handleEnv}
                    />

                    <Env
                        isOpen={this.state.envs[4].isOpen}
                        index={4}
                        vName={this.state.envs[4].vName}
                        default={this.state.envs[4].default}
                        uat={this.state.envs[4].uat}
                        mt={this.state.envs[4].mt}
                        prod={this.state.envs[4].prod}
                        dr={this.state.envs[4].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[5].isOpen}
                        index={5}
                        vName={this.state.envs[5].vName}
                        default={this.state.envs[5].default}
                        uat={this.state.envs[5].uat}
                        mt={this.state.envs[5].mt}
                        prod={this.state.envs[5].prod}
                        dr={this.state.envs[5].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[6].isOpen}
                        index={6}
                        vName={this.state.envs[6].vName}
                        default={this.state.envs[6].default}
                        uat={this.state.envs[6].uat}
                        mt={this.state.envs[6].mt}
                        prod={this.state.envs[6].prod}
                        dr={this.state.envs[6].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[7].isOpen}
                        index={7}
                        vName={this.state.envs[7].vName}
                        default={this.state.envs[7].default}
                        uat={this.state.envs[7].uat}
                        mt={this.state.envs[7].mt}
                        prod={this.state.envs[7].prod}
                        dr={this.state.envs[7].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[8].isOpen}
                        index={8}
                        vName={this.state.envs[8].vName}
                        default={this.state.envs[8].default}
                        uat={this.state.envs[8].uat}
                        mt={this.state.envs[8].mt}
                        prod={this.state.envs[8].prod}
                        dr={this.state.envs[8].dr}
                        onChange={this.handleEnv}
                    />
                    <Env
                        isOpen={this.state.envs[9].isOpen}
                        index={9}
                        vName={this.state.envs[9].vName}
                        default={this.state.envs[9].default}
                        uat={this.state.envs[9].uat}
                        mt={this.state.envs[9].mt}
                        prod={this.state.envs[9].prod}
                        dr={this.state.envs[9].dr}
                        onChange={this.handleEnv}
                    />
                    <div
                        className={`error-${(
                            this.state.prometheus.enabled &&
                            parseInt(this.state.prometheus.port, 10) ===
                                parseInt(this.state.ingress.port, 10)
                        ).toString()}`}
                    >
                        <h3>Ouch!</h3>
                        <p>
                            You are running the service on the same port on prometheus. It is highly
                            reommended that you move prometheus to a different port.
                        </p>
                    </div>
                    <hr />
                    <div className="row">
                            <ButtonGroup className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                                <Button
                                    size="large"
                                    onClick={() => this.copy(valuesYaml)}
                                >
                                    copy to clipboard
                                </Button>
                                <Button>
                                    <a href={this.download(valuesYaml)} className={link()} download="values.yaml">
                                        Download values.yaml
                                    </a>
                                </Button>
                            </ButtonGroup>
                        <div>
                            <code>
                                <pre className="code" ref={this.refPre}>
                                    {valuesYaml}
                                </pre>
                            </code>
                        </div>
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default NewValuesYaml;
