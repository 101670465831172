import React from 'react';

class UID extends React.Component {
    render() {
        return (
            <div>
                <p>
                    You have to configure a non-root user while building the image. Below you can
                    see a sample Dockerfile.
                </p>
                <p>
                    You also need to mention the same user id in values file as{' '}
                    <code>runAsUser: 1000</code>. For more details see Confluence link.{' '}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://confluence.vipps.io/display/TP/Azure+policy+for+AKS"
                    >
                        https://confluence.vipps.io/display/TP/Azure+policy+for+AKS
                    </a>
                </p>
                <code>
                    <pre>
                        FROM mcr.microsoft.com/azure-cli RUN adduser -D javauser USER javauser CMD
                        ["sleep", "300"]
                    </pre>
                </code>
            </div>
        );
    }
}

export default UID;
