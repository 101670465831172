/* eslint-disable no-alert */
/* eslint-disable promise/no-nesting */
import './NetworkInformation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Netmask } from 'netmask';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { UUIDv4 } from 'uuid-v4-validator';
import CytoscapeComponent from 'react-cytoscapejs';
import { storeCurrentPath } from '../LocalRedirectUrlStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Input,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Spinner,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    FormGroup,
    Label,
    UncontrolledTooltip,
} from 'reactstrap';

const defaultVnet = {
    name: '',
    id: '',
    properties: {
        resourceGuid: '',
        addressSpace: {
            addressPrefixes: [],
        },
        subnets: [
            {
                id: '',
                properties: {
                    addressPrefix: '',
                    networkSecurityGroup: {
                        id: '',
                    },
                },
            },
        ],
        virtualNetworkPeerings: [
            {
                id: '',
                properties: {
                    peeringState: '',
                    allowForwardedTraffic: '',
                    remoteAddressSpace: {
                        addressPrefixes: [],
                    },
                    remoteVirtualNetwork: {
                        id: '',
                    },
                },
            },
        ],
    },
    subscriptionId: '',
    resourceGroup: '',
};

const defaultSubscription = {
    name: '',
    subscriptionId: '',
};

class NetworkInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            GUID: '',
            loading: true,
            vnet: defaultVnet,
            currentNumbAddr: 15,
            vnets: [defaultVnet],
            activeTab: 'subnets',
            subscription: defaultSubscription,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            this.secureLogin();
        });
    }

    handleChange(e) {
        this.setState(e);
    }

    handleInputChange(e) {
        this.setState({ currentNumbAddr: e.currentTarget.value });
    }

    secureLogin() {
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const GUID = params.get('GUID') || this.state.GUID;
        const validGUID = UUIDv4.validate(GUID);

        if (!validGUID) {
            window.open(`/start/vnets`, '_self');
            return null;
        }

        var accessToken = window.sessionStorage.getItem('NusseToken');

        if (!accessToken) {
            storeCurrentPath(`/start/vnets/detailed?GUID=${GUID}`);
            return null;
        }

        var headers = new Headers();
        headers.append('Ocp-Apim-Subscription-Key', 'b835365a0dba461c8afa4d8ab79a4745');
        var bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);

        var options = {
            credentials: 'include',
            mode: 'cors',
            headers: headers,
            method: 'GET',
        };

        this.fetchVNet(GUID, options);
    }

    fetchVNet(GUID, options) {
        fetch(
            `https://ece46ec4-6f9c-489b-8fe5-146a89e11635.tech-02.net/nusse-backend/vnet/${encodeURIComponent(
                GUID
            )}`,
            options
        )
            .then((res) => {
                if (res.ok) return res.json();
                else throw new Error(`Status code error: ${res.status}`);
            })
            .then((result) => {
                this.setState({
                    vnet: result,
                    GUID,
                });
                this.fetchSubscriptions(options);
                this.fetchVNets(options);
            })
            .catch((error) => {
                console.log(`error: ${error}`);
                window.open(`/`, '_self');
            });
    }

    fetchSubscriptions(options) {
        fetch(
            `https://ece46ec4-6f9c-489b-8fe5-146a89e11635.tech-02.net/nusse-backend/subscription/all`,
            options
        )
            .then((res) => res.json())
            .then((result) => {
                var subscription = result.find(
                    (obj) => obj.subscriptionId === this.state.vnet.subscriptionId
                );

                this.setState({
                    subscription,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(`error: ${error}`);
                window.open(`/`, '_self');
            });
    }

    fetchVNets(options) {
        fetch(
            `https://ece46ec4-6f9c-489b-8fe5-146a89e11635.tech-02.net/nusse-backend/vnet/all`,
            options
        )
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    vnets: result,
                });
            })
            .catch((error) => {
                console.log(`error: ${error}`);
                window.open(`/`, '_self');
            });
    }

    displayVNet() {
        const { vnet } = this.state;
        if (this.state.GUID === '' || vnet === undefined) return;

        return (
            <div>
                <Nav tabs>
                    <NavItem className="pointer">
                        <NavLink
                            disabled={vnet.properties.subnets.length === 0}
                            onClick={() => this.setState({ activeTab: 'subnets' })}
                            className={classnames({
                                active: this.state.activeTab === 'subnets',
                            })}
                        >
                            <FontAwesomeIcon icon="chevron-down" />
                            Subnets ({vnet.properties.subnets.length})
                        </NavLink>
                    </NavItem>
                    <NavItem className="pointer">
                        <NavLink
                            disabled={vnet.properties.virtualNetworkPeerings.length === 0}
                            onClick={() => this.setState({ activeTab: 'peerings' })}
                            className={classnames({
                                active: this.state.activeTab === 'peerings',
                            })}
                        >
                            <FontAwesomeIcon icon="chevron-down" />
                            Peerings ({vnet.properties.virtualNetworkPeerings.length})
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="subnets" className="spacing">
                        {this.displaySubnets(vnet)}
                        {this.suggestSubnet(vnet)}
                    </TabPane>
                    <TabPane tabId="peerings" className="spacing">
                        {this.displayPeerings(vnet)}
                    </TabPane>
                </TabContent>
            </div>
        );
    }

    displaySubnets(vnet) {
        const { subnets } = vnet.properties;
        if (!subnets.length) return;

        return (
            <ListGroup flush>
                <h4>Subnets in use</h4>
                {subnets.map((subnet) => {
                    var subnetPrefix = new Netmask(subnet.properties.addressPrefix);
                    return (
                        <ListGroupItem key={subnet.id}>
                            <ListGroupItemHeading>
                                <b>{subnet.name}</b> ({subnet.properties.addressPrefix})
                            </ListGroupItemHeading>
                            <ListGroupItemText>
                                NSG:{' '}
                                {subnet.properties.networkSecurityGroup ? (
                                    <>
                                        {subnet.properties.networkSecurityGroup.id.split('/').pop()}{' '}
                                        <a
                                            href={`https://portal.azure.com/#@vipps.no/resource${subnet.properties.networkSecurityGroup.id}/overview`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <FontAwesomeIcon
                                                icon={faExternalLinkAlt}
                                                color="black"
                                            />
                                        </a>
                                    </>
                                ) : (
                                    <i>not applied</i>
                                )}{' '}
                                <br />
                                Number of IP addresses: {subnetPrefix.size} <br />
                                First usable IP address: {subnetPrefix.first} <br />
                                Last usable IP address: {subnetPrefix.last}
                            </ListGroupItemText>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        );
    }

    displayPeerings(vnet) {
        const peerings = vnet.properties.virtualNetworkPeerings;
        if (!peerings.length) return;

        return (
            <div>
                {this.state.activeTab === 'peerings' ? this.drawPeerings() : ''}
                <ListGroup flush>
                    <h4>Peerings configured</h4>
                    {peerings.map((peering) => {
                        return (
                            <ListGroupItem key={peering.id}>
                                <ListGroupItemHeading
                                    style={{
                                        marginBottom: '0.1em',
                                    }}
                                >
                                    {peering.name}
                                </ListGroupItemHeading>
                                <ListGroupItemText
                                    style={{
                                        color:
                                            peering.properties.peeringState === 'Connected'
                                                ? 'green'
                                                : 'red',
                                        fontStyle: 'italic',
                                        marginBottom: '0.3em',
                                    }}
                                >
                                    {peering.properties.peeringState}
                                </ListGroupItemText>
                                <ListGroupItemText>
                                    Allow forwarded traffic:{' '}
                                    {peering.properties.allowForwardedTraffic ? 'Yes' : 'No'} <br />
                                    Remote VNet name:{' '}
                                    {peering.properties.remoteVirtualNetwork.id
                                        .split('/')
                                        .pop()}{' '}
                                    <br />
                                    Remote address space:{' '}
                                    {peering.properties.remoteAddressSpace.addressPrefixes.toString()}
                                </ListGroupItemText>
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            </div>
        );
    }

    suggestSubnet(vnet) {
        const supernet = vnet.properties.addressSpace.addressPrefixes.toString();
        if (supernet.length < 2 || supernet.length > 18 || this.state.GUID === '') return;

        const netmaskSupernet = new Netmask(supernet);

        return (
            <div>
                <h4>Available subnet</h4>
                <FormGroup className="col-md-3">
                    <Label for="numbOfAddr">
                        Number of IP addresses needed
                        <span role="img" aria-label="explains available subnet" id="tooltip">
                            ❓
                        </span>
                        <UncontrolledTooltip placement="right" target="tooltip">
                            How many databases, VMs or other resources do you plan to deploy in this
                            subnet?
                        </UncontrolledTooltip>
                    </Label>
                    <Input
                        id="numbOfAddr"
                        onInput={this.handleInputChange}
                        type="number"
                        min="4"
                        max="65529"
                        value={this.state.currentNumbAddr}
                    />
                </FormGroup>
                <p className="output">{this.getNextAvailable(netmaskSupernet)}</p>
            </div>
        );
    }

    getNextAvailable(netmaskSupernet) {
        var subnetMask = '29';

        var suggestedSubnet = new Netmask(netmaskSupernet.base, subnetMask);

        while (suggestedSubnet.size - 5 < this.state.currentNumbAddr) {
            suggestedSubnet = new Netmask(netmaskSupernet.base, --subnetMask);
        }

        try {
            while (this.overlaps(suggestedSubnet)) {
                suggestedSubnet = suggestedSubnet.next();
                if (!netmaskSupernet.contains(suggestedSubnet))
                    return 'No available subnets within this VNet with the given size.';
            }
        } catch (e) {
            return 'No available subnets within this VNet with the given size.';
        }

        return `${suggestedSubnet.base}/${suggestedSubnet.bitmask} with ${
            suggestedSubnet.size - 5
        } usable IP addresses is available within this VNet.`;
    }

    overlaps(suggestedSubnet) {
        var overlap = false;

        this.state.vnet.properties.subnets.forEach((subnet) => {
            var existingSubnet = new Netmask(subnet.properties.addressPrefix);

            if (suggestedSubnet.bitmask < existingSubnet.bitmask) {
                if (suggestedSubnet.contains(existingSubnet)) overlap = true;
            } else {
                if (existingSubnet.contains(suggestedSubnet)) overlap = true;
            }
        });
        return overlap;
    }

    drawPeerings() {
        const { vnet } = this.state;
        if (this.state.GUID === '' || vnet === undefined) return;

        const peerings = vnet.properties.virtualNetworkPeerings;
        if (!peerings.length) return;

        var elements = [];
        elements.push({
            data: {
                id: vnet.id,
                label: vnet.name,
                type: 'root',
            },
        });

        peerings.forEach((peering) => {
            var remoteId = peering.properties.remoteVirtualNetwork.id;
            elements.push({
                data: {
                    id: remoteId,
                    label: remoteId.split('/').pop(),
                    backgroundColor:
                        peering.properties.peeringState === 'Connected' ? '#080' : '#d00',
                },
            });
            elements.push({
                data: {
                    id: peering.id,
                    source: vnet.id,
                    target: remoteId,
                    lineColor: peering.properties.peeringState === 'Connected' ? '#080' : '#d00',
                    lineStyle: peering.properties.peeringState === 'Connected' ? 'solid' : 'dashed',
                },
            });
        });

        const layout = {
            name: 'concentric',
            minNodeSpacing: 150,
        };

        return (
            <CytoscapeComponent
                className="row left-align center-border"
                elements={elements}
                layout={layout}
                cy={(cy) => {
                    this.cy = cy;
                    this.cy.nodes().on('click', (e) => {
                        var clickedNode = e.target.data();

                        var clickedVNet = this.state.vnets.find(
                            (obj) => obj.id.toLowerCase() === clickedNode.id.toLowerCase()
                        );

                        if (!clickedVNet) {
                            var cleanUp = window.confirm(
                                `${clickedNode.label} no longer exists! Would you like to clean up this peering right away?`
                            );
                            if (cleanUp) {
                                var rootId = this.cy.nodes('[type = "root"]').data().id;
                                window.open(
                                    `https://portal.azure.com/#@vipps.no/resource${rootId}/peerings`
                                );
                            }
                            return;
                        }

                        this.setState({ loading: true });

                        var GUID = clickedVNet.properties.resourceGuid;
                        window.open(
                            `/start/vnets/detailed?GUID=${encodeURIComponent(GUID)}`,
                            '_self'
                        );
                    });
                    this.cy.nodes().on('mouseover', (e) => {
                        if (e.target.data().type !== 'root') {
                            this.cy.container().style.cursor = 'pointer';
                        }
                    });
                    this.cy.nodes().on('mouseout', (e) => {
                        this.cy.container().style.cursor = 'default';
                    });
                }}
                stylesheet={[
                    {
                        selector: 'node',
                        style: {
                            backgroundColor: 'data(backgroundColor)',
                            label: 'data(label)',
                            height: '4em',
                            width: '4em',
                            'font-size': '1.3em',
                            'background-image': '/img/external-link-alt-solid.svg',
                            'background-clip': 'none',
                            'background-width': '2.3em',
                            'background-height': '2.3em',
                            'text-valign': 'bottom',
                        },
                    },
                    {
                        selector: 'node[type="root"]',
                        style: {
                            backgroundColor: '#722ac9',
                            'font-size': '1.5em',
                            'background-image': 'none',
                            'text-valign': 'top',
                        },
                    },
                    {
                        selector: 'edge',
                        style: {
                            'curve-style': 'haystack',
                            width: 6,
                            opacity: 0.8,
                            'line-color': 'data(lineColor)',
                            'line-style': 'data(lineStyle)',
                        },
                    },
                ]}
                style={{
                    width: '50em',
                    height: '40em',
                }}
            />
        );
    }

    render() {
        /* jshint ignore:start */
        const { loading, vnet, subscription } = this.state;

        return (
            <main className="body">
                <Link to="/start/vnets">
                    <FontAwesomeIcon icon="chevron-up" size="3x" />
                </Link>

                <div className="wallpaper">
                    <div className="container">
                        {loading ? (
                            <Spinner />
                        ) : (
                            <div>
                                <header>
                                    <h1>
                                        {vnet.name} (
                                        {vnet.properties.addressSpace.addressPrefixes.toString()}){' '}
                                        <a
                                            href={`https://portal.azure.com/#@vipps.no/resource${vnet.id}/overview`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <FontAwesomeIcon
                                                icon={faExternalLinkAlt}
                                                color="#722ac9"
                                            />
                                        </a>
                                    </h1>
                                    <h3>
                                        {subscription.name} &#7111; {vnet.resourceGroup}
                                    </h3>
                                </header>
                                <div className="row left-align">{this.displayVNet()}</div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default NetworkInformation;
