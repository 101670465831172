import './FlowNavigation.css';
import React from 'react';
import StepIndicator from '@vippsno/ds-step-indicator';
import { Link } from 'react-router-dom';
import { link } from '@vippsno/ds-typography';
import { CaretIcon } from '@vippsno/ds-icon';
import { Grid, GridRow, GridColumn, GridContainer } from '@vippsno/ds-grid';

class FlowNavigation extends React.Component {

    render() {

        let next;
        let previous;

        if (this.props.previous) {
            previous = <Link  to={this.props.previous} className={link()} >
                    <CaretIcon size="large" direction="left" className="margin-right-s" />
                </Link>;
        }

        if (this.props.next) {
            next = <Link to={this.props.next} className={link()}>
                        <CaretIcon size="large" direction="right" className="margin-right-s" />
                    </Link>;
        }

        return (
            <nav className="flow-navigation">
                <GridContainer>
                    <Grid flexBox>
                        <GridRow>
                            <GridColumn sm={1}>
                            {previous}
                            </GridColumn>
                            <GridColumn sm={3} className="center">
                                <StepIndicator 
                                    currentStep={this.props.currentStep} 
                                    numberOfSteps={this.props.numberOfSteps} 
                                />
                            </GridColumn>
                            <GridColumn sm={1}>
                            {next}
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </GridContainer>
            </nav>
        );
        /* jshint ignore:end */
    }
}

export default FlowNavigation;
