import './Finish.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Heading1 } from '@vippsno/ds-typography';
import { BackButton } from '@vippsno/ds-button'
import { Link } from 'react-router-dom';
import { link } from '@vippsno/ds-typography';


class Finish extends React.Component {
    render() {
        /* jshint ignore:start */
        return (
            <main className="body">
                    <div className="container">
                        <header className="finished">
                            <Heading1>You are done!</Heading1>
                            <span role="img" aria-label="You are done" className="finished-icon">
                                🎉
                            </span>
                        </header>
                    <BackButton>
                        <Link to="/" className={link()}>Go home</Link>
                    </BackButton>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default Finish;
