const sessionStorageKey = 'local-url-nusse';

export const storeCurrentPath = (path) => {
    if (path) {
        window.sessionStorage.setItem(sessionStorageKey, path);
    } else {
        window.sessionStorage.setItem(
            sessionStorageKey,
            window.location.pathname + window.location.search
        );
    }
};

export const getStoredPath = () => {
    return window.sessionStorage.getItem(sessionStorageKey);
};

export const clearStoredPath = () => {
    window.sessionStorage.removeItem(sessionStorageKey);
};
