import React from 'react';
import './Env.css';

class Env extends React.Component {
    constructor(props) {
        super(props);
        this.handleDefault = this.handleDefault.bind(this);
        this.handleDr = this.handleDr.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleUat = this.handleUat.bind(this);
        this.handleProd = this.handleProd.bind(this);
        this.handleMt = this.handleMt.bind(this);
    }

    getState(props) {
        return {
            default: props.default,
            dr: props.dr,
            index: props.index,
            isOpen: props.isOpen,
            mt: props.mt,
            prod: props.prod,
            uat: props.uat,
            vName: props.vName,
        };
    }

    handleName(e) {
        const env = this.getState(this.props);
        env.vName = e.target.value;
        this.props.onChange(env);
    }

    handleDefault(e) {
        const env = this.getState(this.props);
        env.default = e.target.value;
        this.props.onChange(env);
    }

    handleUat(e) {
        const env = this.getState(this.props);
        env.uat = e.target.value;
        this.props.onChange(env);
    }

    handleMt(e) {
        const env = this.getState(this.props);
        env.mt = e.target.value;
        this.props.onChange(env);
    }

    handleProd(e) {
        const env = this.getState(this.props);
        env.prod = e.target.value;
        this.props.onChange(env);
    }

    handleDr(e) {
        const env = this.getState(this.props);
        env.dr = e.target.value;
        this.props.onChange(env);
    }

    render() {
        /* jshint ignore:start */
        return (
            <div className={`env ${this.props.isOpen !== false ? '' : 'hidden'}`}>
                <div className="row">
                    <div className="col-md-3">
                        <input
                            type="text"
                            data-testid="name"
                            defaultValue={this.props.vName}
                            placeholder="MY_VALUE"
                            onChange={this.handleName}
                        ></input>
                    </div>
                    <div className="col-md-1">
                        <label>uat</label>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="text"
                            data-testid="uat"
                            className="wide"
                            defaultValue={this.props.uat}
                            onChange={this.handleUat}
                        ></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-1">
                        <label>mt</label>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="text"
                            data-testid="mt"
                            className="wide"
                            defaultValue={this.props.mt}
                            onChange={this.handleMt}
                        ></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-1">
                        <label>prod</label>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="text"
                            data-testid="prod"
                            className="wide"
                            defaultValue={this.props.prod}
                            onChange={this.handleProd}
                        ></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-1">
                        <label>default</label>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="text"
                            data-testid="default"
                            className="wide"
                            defaultValue={this.props.default}
                            onChange={this.handleDefault}
                        ></input>
                    </div>
                </div>
            </div>
        );
        /* jshint ignore:end */
    }
}
export default Env;
