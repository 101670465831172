import './SecurityPipeline.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Heading1, Heading2, Label } from '@vippsno/ds-typography';
import { Grid, GridBlock } from '@vippsno/ds-grid';
import { link } from '@vippsno/ds-typography';
import { Button, ButtonGroup  } from '@vippsno/ds-button'
import { ClipboardOutlineIcon, SecurityOutlineIcon } from '@vippsno/ds-icon';
import { Input, InputGroup, Toggle } from '@vippsno/ds-form';
import { Message } from '@vippsno/ds-message'
import { marginBottomMedium } from '@vippsno/ds-spacing';
import Select from 'react-select';

const defaultSemgrep = {
    additionalArgs: '',
    codeScanningRuleSet: 'rules/java',
    outputPath: '',
    pathToScan: '.',
    secretsScanningRuleSet: 'rules/vipps/generic/secrets',
    warnOnError: true,
    exclude: '--exclude vipps-semgrep/ --exclude="*/test/*" --exclude="*/tests/*"'
};

const rulesetOptions = [
    { value: 'rules/java', label: 'java' },
    { value: 'rules/go', label: 'go' },
    { value: 'rules/typescript', label: 'typescript' },
    { value: 'rules/python', label: 'python' }
]

const defaultContainerScan = {
    registry: 'acrvippsakscmn',
    registryResourceGroup: 'rg-acraks-common',
    armServiceConnection: 'vipps-platform-VCE-PROD'
}

class SecurityPipeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: '',
            semgrep: defaultSemgrep,
            containerScan: defaultContainerScan,
            rulesets: null 
        };

        this.copy = this.copy.bind(this);
        this.download = this.download.bind(this);
        this.handleCodeScanChange = this.handleCodeScanChange.bind(this);
        this.handleContainerScanChange = this.handleContainerScanChange.bind(this);
        this.handleRulesetsOnChange = this.handleRulesetsOnChange.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        if (params.has('gitsc')) {
            this.setState((s) => ({
                ...s,
                containerScan: {
                    ...s.containerScan,
                    githubServiceConnection: params.get('gitsc').toLowerCase()
                }
            }))
        }

        try{
            const semgrep = params.has('semgrep') ? JSON.parse(params.get('semgrep')) : this.state.semgrep;
            const containerScanParams = params.has('containerScan') ? JSON.parse(params.get('containerScan')) : this.state.containerScan;

            if (semgrep || containerScanParams){
                this.setState((s) => ({
                    params: this.createParams(this.state),
                    semgrep: {
                        ...s.semgrep,
                        ...semgrep
                    },
                    containerScan: {
                        ...s.containerScan,
                        ...containerScanParams
                    }
                }));
            }

        } catch (e){
            // Something went wrong with our JSON parsing. It is OK, but we'll ignore the parameters.
            console.log(e);
        }

    }

    copy(text) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert('azure-pipelines.yml copied to clipboard');
            })
            .catch((err) => {
                alert('Error in copying azure-pipelines.yml to clipboard: ', err);
            });
    }

    createParams(state) {
        var paramValues = [];

        if (state.semgrep !== defaultSemgrep) {
            paramValues.push(`semgrep=${encodeURIComponent(JSON.stringify(this.state.semgrep))}`);
        }

        if (state.containerScan !== defaultContainerScan) {
            paramValues.push(`containerScan=${encodeURIComponent(JSON.stringify(this.state.containerScan))}`);
        }

        const params = (paramValues.length !== 0 ? '?' : '') + paramValues.join('&');
        return params;
    }

    download(text) {
        const blob = new Blob([text], { type: 'text/plain' });
        return URL.createObjectURL(blob);
    }

    handleCodeScanChange(e) {
        this.setState(
            (s) => ({
                ...s,
                semgrep: {
                    ...s.semgrep,
                    [e.target.name]: e.target.value,
                },
            }),
            (_) => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleContainerScanChange(e) {
        this.setState(
            (s) => ({
                ...s,
                containerScan: {
                    ...s.containerScan,
                    [e.target.name]: e.target.value,
                },
            }),
            (_) => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    getCommaSeparatedRulesets(rulesets) {
        let commaSeparatedRulesets = "";
        rulesets.forEach(ruleset => {
             commaSeparatedRulesets += ruleset.value + ","
        });
        return commaSeparatedRulesets.slice(0, -1)
    }

    handleRulesetsOnChange(selectedRulesets) {
        this.setState(
            (s) => ({
                ...s,
                rulesets: selectedRulesets,
                semgrep: {
                    ...s.semgrep,
                    codeScanningRuleSet: this.getCommaSeparatedRulesets(selectedRulesets)
                }
            }),
            (_) => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    render() {
        const pipelineYaml = `# Generated from
# ${window.location.protocol}//${window.location.host}${window.location.pathname}${
            this.state.params
        }
# You can always visit this link to download this pipeline.

pool:
  vmImage: "ubuntu-latest"

trigger: none

schedules:
- cron: "0 0 * * *"
  displayName: Daily midnight run (UTC)
  branches:
    include:
    - main
    - master

pr:
  branches:
    include:
      - '*'
  paths:
    include:
      - '*'
    exclude:
      - README.md
      - docs/*
      - deploy/*

resources:
  repositories:
    - repository: vipps-semgrep
      type: git
      name: Test/vipps-semgrep
      ref: refs/heads/main

    - repository: container-scanning-pipeline
      type: github
      name: vippsas/container-scanning-pipeline
      ref: refs/tags/v1.0
      endpoint: '${this.state.containerScan.githubServiceConnection}'

  containers:
    - container: 'containerToScan'
      type: ACR
      azureSubscription: '${this.state.containerScan.armServiceConnection}'
      resourceGroup: '${defaultContainerScan.registryResourceGroup}'
      registry: '${defaultContainerScan.registry}'
      repository: '${this.state.containerScan.repository}'

stages:
  - stage: "CodeSecurityScan"
    displayName: "Code security scan"
    jobs:
    - job: CodeSecurityScan
      displayName: "Code Security Scan"
      condition: or(eq(variables['build.Reason'], 'PullRequest'), eq(variables['Build.SourceBranch'], 'refs/heads/main'))
      steps:
        - checkout: self
        - checkout: vipps-semgrep
        - template: template-semgrep.yml@vipps-semgrep
          parameters:
            codeScanningRuleSet: '${this.state.semgrep.codeScanningRuleSet.trim()}'
            exclude: '${this.state.semgrep.exclude.trim()}'
            outputPath: '${this.state.semgrep.outputPath.trim()}'
            pathToScan: '${this.state.semgrep.pathToScan.trim()}'
            warnOnError: '${this.state.semgrep.warnOnError}'
            additionalArgs: '${this.state.semgrep.additionalArgs.trim()}'

  - stage: "ContainerScan"
    displayName: "Container security scan"
    jobs:
    - job: ContainerSecurityScan
      displayName: "Container security scan"
      pool:
        vmImage: $(vmImage)
      steps:
        - checkout: container-scanning-pipeline
        - template: template-trivy.yml@container-scanning-pipeline
          parameters:
            imageToScan: '$(resources.container.containerToScan.URI)'
            containerRegistryServiceConnection: '${this.state.containerScan.armServiceConnection}'
            serviceConnectionType: arm
      `;

        /* jshint ignore:start */
        return (
            <main className="body">
                <div className="container">
                    <header>
                        <Heading1><SecurityOutlineIcon size="large" className="margin-right-s" /> Code Security Pipeline</Heading1>
                            <Message className={marginBottomMedium()}>This page helps you set up a code security pipeline for you application in{' '}
                            <a href="https://dev.azure.com" target="_blank" rel="noreferrer noopener" className={link()}>Azure DevOps</a>.</Message>
                        <p>
                            The code scanning pipeline works by scanning your source code for patterns of common vulnerabilities
                            with <a href="https://semgrep.dev" target="_blank" rel="noreferrer noopener" className={link()}>semgrep</a>.
                            It will also look for patterns of known secrets in your source code such as database passwords or API keys which should be kept in an Azure key vault to control access and make them easier to change. We have streamlined and
                            customized Semgrep to fit the needs of our company.
                        </p>
                        <p>
                            The best way to use this code is a separate pipeline. It is a good
                            quality tool that you should run on every pull request. We have added a
                            pull request trigger for that.
                        </p>
                        <p>
                            The pipeline also scans your container images using <a href="https://aquasecurity.github.io/trivy/" target="_blank" rel="noreferrer noopener" className={link()}>Trivy</a>. 
                            Trivy is a neat scanner tool that will detect vulnerabilities in OS-packages as well as language-specific packages, e.g. JAR files. 
                            The container scanning pipeline is configured by setting the name of your Azure Container Registry Repository and it is triggered to scan on a daily schedule. 
                            The results of the scan can be found on the <code>Scan</code> tab of your pipeline.
                        </p>
                        <p>
                            Once you are done filling in the input fields you will get a new file <code>azure-pipelines-pr.yml</code> that
                            you must put in the <code>deploy</code> directory in the root of your Git project.
                        </p>
                    </header>
                    <hr></hr>

                    <Grid>
                        <GridBlock sm={6}>
                            <Heading2>Code Scanning Parameters</Heading2>
                            <Label>Rulesets for your languages</Label>
                            <Select 
                                value={this.state.rulesets}
                                options={rulesetOptions} 
                                onChange={this.handleRulesetsOnChange}
                                isMulti 
                            />
                            <InputGroup label="Path to scan">
                                <Input
                                    type="text"
                                    name="pathToScan"
                                    defaultValue={this.state.semgrep.pathToScan}
                                    onChange={this.handleCodeScanChange}
                                    placeholder="The default path that will be scanned is the current folder '.'"
                                ></Input>
                            </InputGroup>
                            <InputGroup label="Additional arguments">
                                <Input
                                    type="text"
                                    name="additionalArgs"
                                    defaultValue={this.state.semgrep.additionalArgs}
                                    onChange={this.handleCodeScanChange}
                                    placeholder="Any additional arguments to Semgrep"
                                ></Input>
                            </InputGroup>
                            <InputGroup label="Paths to exclude">
                                <Input
                                    type="text"
                                    name="exclude"
                                    defaultValue={this.state.semgrep.exclude}
                                    onChange={this.handleCodeScanChange}
                                    placeholder='--exclude vipps-semgrep/ --exclude="*/test/*" --exclude="*/tests/*"'
                                ></Input>
                            </InputGroup>
                            <InputGroup label="Output path">
                                <Input
                                    type="text"
                                    name="outputPath"
                                    defaultValue={this.state.semgrep.outputPath}
                                    onChange={this.handleCodeScanChange}
                                    placeholder="Specify where results should be stored, target path must exist"
                                ></Input>
                            </InputGroup>
                            <div>
                                <div htmlFor="warnOnError">Warn on error
                                </div>
                                    <Toggle id="warnOnError" onChange={() => this.setState({semgrep: {...this.state.semgrep, warnOnError: !this.state.semgrep.warnOnError}})} checked={this.state.semgrep.warnOnError} />
                            </div>
                        </GridBlock>
                        <GridBlock sm={6}>
                            <Heading2>Container Scanning parameters</Heading2>
                            <InputGroup label="ACR Repository name">
                                <Input
                                    type="text"
                                    name="repository"
                                    required
                                    defaultValue={this.state.containerScan.repository}
                                    onChange={this.handleContainerScanChange}
                                    placeholder="Azure Container Registry repository name where you push your Docker images"
                                ></Input>
                            </InputGroup>
                            <InputGroup label="GitHub Service Connection">
                                <Input
                                    type="text"
                                    name="githubServiceConnection"
                                    required
                                    defaultValue={this.state.containerScan.githubServiceConnection}
                                    onChange={this.handleContainerScanChange}
                                    placeholder="GitHub service connection name. Format is 'github-<your-devops-project-name>'"
                                ></Input>
                            </InputGroup>
                        </GridBlock>
                    </Grid>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <ButtonGroup>
                                <Button
                                    type="button"
                                    onClick={() => this.copy(pipelineYaml)}
                                    size="small"
                                    icon={<ClipboardOutlineIcon className="margin-right-s"  />}
                                >
                                    Copy to clipboard
                                </Button>
                                <Button
                                    size="small"
                                >
                                    <a href={this.download(pipelineYaml)} download="azure-pipelines-pr.yml" className={link()}>
                                        Download azure-pipelines-pr.yml
                                    </a>
                                </Button>
                            </ButtonGroup>
                            <code>
                                <pre className="code">{pipelineYaml}</pre>
                            </code>
                        </div>
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default SecurityPipeline;
