/* eslint-disable no-alert */
import './NewPipeline.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Heading1 } from '@vippsno/ds-typography';
import { link } from '@vippsno/ds-typography';
import { Button, ButtonGroup } from '@vippsno/ds-button';
import { marginBottomMedium, marginTopMedium } from '@vippsno/ds-spacing';

const ApiRoot = 'deploy/apim/';
const defaultRunAsUserId = 1000;

const defaultDocker = {
    name: '',
    registry: 'acrvippsakscmn.azurecr.io',
    repository: 'vipps',
};

const defaultApim = {
    enabled: true,
    apiId: '',
    apiBasePath: '',
    apiFilesDirectory: '',
};

const defaultSCProd = 'vipps-platform-VCE-PROD';
const defaultSCTest = 'vipps-platform-VCE-UAT';

const defaultIngress = {
    enabled: true,
    proxyIngressEnabled: false,
    port: 8080,
    path: '/',
    extendedBodySize: false,
};

class NewPipeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'pipeline',
            apim: defaultApim,
            armServiceConnectionPROD: defaultSCProd,
            armServiceConnectionTEST: defaultSCTest,
            docker: defaultDocker,
            dr: false,
            gitServiceConnection: '',
            identity: '',
            ingress: defaultIngress,
            mt: true,
            namespace: '',
            params: '',
            prod: false,
            runAsUserId: defaultRunAsUserId,
            uat: true,
        };

        this.copy = this.copy.bind(this);
        this.download = this.download.bind(this);
        this.handleApimApiBasePath = this.handleApimApiBasePath.bind(this);
        this.handleApimApiId = this.handleApimApiId.bind(this);
        this.handleApimEnabled = this.handleApimEnabled.bind(this);
        this.handleArmAksServiceConnectionPROD = this.handleArmAksServiceConnectionPROD.bind(this);
        this.handleArmAksServiceConnectionTEST = this.handleArmAksServiceConnectionTEST.bind(this);
        this.handleDockerName = this.handleDockerName.bind(this);
        this.handleDockerRegistry = this.handleDockerRegistry.bind(this);
        this.handleDockerRepository = this.handleDockerRepository.bind(this);
        this.handleDr = this.handleDr.bind(this);
        this.handleGitServiceConnection = this.handleGitServiceConnection.bind(this);
        this.handleIdentity = this.handleIdentity.bind(this);
        this.handleRunAsUserId = this.handleRunAsUserId.bind(this);
        this.handleIngressPath = this.handleIngressPath.bind(this);
        this.handleMt = this.handleMt.bind(this);
        this.handleNamespace = this.handleNamespace.bind(this);
        this.handleProd = this.handleProd.bind(this);
        this.handleUat = this.handleUat.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const identity = params.get('identity') || this.state.identity;
        this.setState({ identity: identity });

        const gitsc = params.get('gitsc') || this.state.gitServiceConnection;
        this.setState({ gitServiceConnection: gitsc });

        const sc = params.get('sc') || this.state.armServiceConnectionPROD;
        this.setState({ armServiceConnectionPROD: sc });

        const sctest = params.get('sctest') || this.state.armServiceConnectionTEST;
        this.setState({ armServiceConnectionTEST: sctest });

        const namespace = params.get('namespace') || this.state.namespace;
        this.setState({ namespace: namespace });

        const apiBasePath = params.get('apipath') || this.state.apim.apiBasePath;
        const apiId = params.get('apiid') || this.state.apim.apiId;
        const hasApim = params.get('apim') || this.state.apim.enabled;
        const ingress = JSON.parse(params.get('ingress')) || this.state.ingress;
        const mt = params.has('mt') ? params.get('mt') === 'true' : this.state.mt;
        const name = params.get('name') || this.state.docker.name;
        const prod = params.has('prod') ? params.get('prod') === 'true' : this.state.prod;
        const registry = this.state.docker.registry; // params.get('registry')
        const repository = this.state.docker.repository; // params.get('repository')
        const uat = params.has('uat') ? params.get('uat') === 'true' : this.state.uat;

        const runAsUserId = parseInt(params.get('runasuserid'), 10) || this.state.runAsUserId;

        const apiFilesDirectory = ApiRoot + apiId;
        this.setState((_) => ({
            apim: { enabled: hasApim, apiId: apiId, apiBasePath: apiBasePath, apiFilesDirectory },
            docker: { name: name, registry: registry, repository: repository },
            ingress: ingress,
            mt: mt,
            params: this.createParams(this.state),
            prod: prod,
            uat: uat,
            runAsUserId: runAsUserId,
        }));
    }

    copy(text, filename) {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert(`${filename} copied to clipboard`);
            })
            .catch((err) => {
                alert(`Error in copying ${filename} to clipboard`, err);
            });
    }

    createParams(newPipelineState) {
        var paramValues = [];
        if (newPipelineState.identity !== '') {
            paramValues.push(`identity=${encodeURIComponent(newPipelineState.identity)}`);
        }

        if (newPipelineState.namespace !== '') {
            paramValues.push(`namespace=${encodeURIComponent(newPipelineState.namespace)}`);
        }

        if (newPipelineState.docker.name !== '') {
            paramValues.push(`name=${encodeURIComponent(newPipelineState.docker.name)}`);
        }

        /*
      if (newPipelineState.docker.registry !== '') {
        paramValues.push('registry=' + encodeURIComponent(newPipelineState.docker.registry));
      }

      if (newPipelineState.docker.repository !== '') {
        paramValues.push('repository=' + encodeURIComponent(newPipelineState.docker.repository));
      }
      */

        if (newPipelineState.gitServiceConnection !== '') {
            paramValues.push(`gitsc=${encodeURIComponent(newPipelineState.gitServiceConnection)}`);
        }

        if (newPipelineState.armServiceConnectionPROD !== defaultSCProd) {
            paramValues.push(`sc=${encodeURIComponent(newPipelineState.armServiceConnectionPROD)}`);
        }

        if (newPipelineState.armServiceConnectionTEST !== defaultSCTest) {
            paramValues.push(
                `sctest=${encodeURIComponent(newPipelineState.armServiceConnectionTEST)}`
            );
        }

        if (newPipelineState.apim.enabled !== true) {
            paramValues.push(`apim=${encodeURIComponent(newPipelineState.apim.enabled)}`);
        }

        if (newPipelineState.apim.apiId !== '') {
            paramValues.push(`apiid=${encodeURIComponent(newPipelineState.apim.apiId)}`);
        }

        if (newPipelineState.apim.apiBasePath !== '') {
            paramValues.push(`apipath=${encodeURIComponent(newPipelineState.apim.apiBasePath)}`);
        }

        if (newPipelineState.uat === false) {
            paramValues.push(`uat=${newPipelineState.uat.toString()}`);
        }

        if (newPipelineState.mt === false) {
            paramValues.push(`mt=${newPipelineState.mt.toString()}`);
        }

        if (newPipelineState.prod === true) {
            paramValues.push(`prod=${newPipelineState.prod.toString()}`);
        }

        if (newPipelineState.runAsUserId !== defaultRunAsUserId) {
            paramValues.push(`runasuserid=${newPipelineState.runAsUserId.toString()}`);
        }

        const params = (paramValues.length !== 0 ? '?' : '') + paramValues.join('&');
        return params;
    }

    download(text) {
        const blob = new Blob([text], { type: 'text/plain' });
        return URL.createObjectURL(blob);
    }

    handleApimEnabled(e) {
        this.setState(
            (s) => ({
                ...s,
                apim: {
                    ...s.apim,
                    enabled: e.target.checked,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleApimApiId(e) {
        this.setState(
            (s) => ({
                ...s,
                apim: {
                    ...s.apim,
                    apiId: e.target.value,
                    apiFilesDirectory: ApiRoot + e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleApimApiBasePath(e) {
        this.setState(
            (s) => ({
                ...s,
                apim: {
                    ...s.apim,
                    apiBasePath: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleArmAksServiceConnectionPROD(e) {
        const acr = e.target.value;
        this.setState(
            (_) => ({
                armServiceConnectionPROD: acr,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleArmAksServiceConnectionTEST(e) {
        const acr = e.target.value;
        this.setState(
            (_) => ({
                armServiceConnectionTEST: acr,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleDockerName(e) {
        this.setState(
            (s) => ({
                ...s,
                docker: {
                    ...s.docker,
                    name: e.target.value,
                },
            }),
            (s) => {
                this.setState({ params: this.createParams(this.state) });
                this.props.onChange({
                    docker: this.state.docker,
                    gitServiceConnection: this.state.gitServiceConnection,
                });
            }
        );
    }

    handleDockerRegistry(e) {
        this.setState(
            (s) => ({
                ...s,
                docker: {
                    ...s.docker,
                    registry: e.target.value,
                },
            }),
            (s) => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleDockerRepository(e) {
        this.setState(
            (s) => ({
                ...s,
                docker: {
                    ...s.docker,
                    repository: e.target.value,
                },
            }),
            (s) => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleGitServiceConnection(e) {
        this.setState(
            (_) => ({
                gitServiceConnection: e.target.value,
            }),
            (s) => {
                this.setState({ params: this.createParams(this.state) });
                this.props.onChange({
                    docker: this.state.docker,
                    gitServiceConnection: this.state.gitServiceConnection,
                });
            }
        );
    }

    handleIdentity(e) {
        this.setState(
            (_) => ({
                identity: e.target.value,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleRunAsUserId(_) {
        this.setState(
            (s) => ({
                runAsUserId: -s.runAsUserId,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleIngressPath(e) {
        this.setState(
            (s) => ({
                ingress: {
                    ...s.ingress,
                    path: e.target.value,
                },
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
                this.props.onChange({
                    docker: this.state.docker,
                });
            }
        );
    }

    handleNamespace(e) {
        this.setState(
            (_) => ({
                namespace: e.target.value,
            }),
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleUat(e) {
        this.setState(
            {
                uat: e.target.checked,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleMt(e) {
        this.setState(
            {
                mt: e.target.checked,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleProd(e) {
        this.setState(
            {
                prod: e.target.checked,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    handleDr(e) {
        this.setState(
            {
                dr: e.target.checked,
            },
            () => {
                this.setState({ params: this.createParams(this.state) });
            }
        );
    }

    toggle(activeTab) {
        this.setState({
            activeTab: activeTab,
        });
    }

    render() {
        const hasApim = this.state.apim.enabled;
        const apimblock = `# APIM section
  apiId: ${this.state.apim.apiId}
  apiBasePath: ${this.state.apim.apiBasePath}
  apimFilesDirectory: ${this.state.apim.apiFilesDirectory}
`;
        const apimconfig = hasApim ? apimblock : '';
        const publishApimApi = hasApim
            ? `
      - task: PublishBuildArtifacts@1
        displayName: "Publish APIM policy"
        inputs:
          pathtoPublish: ${this.state.apim.apiFilesDirectory}
          ArtifactName: $(apimFilesDirectory)
          publishLocation: 'Container'
`
            : '';

        const deployApimApi = hasApim
            ? `
  - stage: "DeployApimApiUAT"
    displayName: "Deploy deploy APIM policy to UAT"
    dependsOn: ["PushToACR"]
    condition: and(and(succeeded(), eq(variables['Build.SourceBranch'], variables['mainBranch'])), eq(\${{ parameters.deployToUAT }}, true))
    jobs:
    - deployment: Deploy
      displayName: "Deploy deploy APIM policy to UAT"
      pool:
        vmImage: $(vmImage)
      environment: 'uat'
      strategy:
        runOnce:
          deploy:
            steps:
              - template: template/apim-api-template.yml@kubedeploy
                parameters:
                  ENVIRONMENT: "uat"
                  APIM_SERVICE_CONNECTION: "$(armServiceConnectionTEST)"
                  API_ID: "$(apiId)"
                  APIM_FILES_DIRECTORY: "$(apimFilesDirectory)"
                  API_BASE_PATH: "$(apiBasePath)"

  - stage: "DeployApimApiMT"
    displayName: "Deploy deploy APIM policy to MT"
    dependsOn: ["PushToACR", "DeployApimApiUAT"]
    condition: and(and(succeeded(), eq(variables['Build.SourceBranch'], variables['mainBranch'])), eq(\${{ parameters.deployToMT }}, true))
    jobs:
    - deployment: Deploy
      displayName: "Deploy deploy APIM policy to MT"
      pool:
        vmImage: $(vmImage)
      environment: 'mt'
      strategy:
        runOnce:
          deploy:
            steps:
              - template: template/apim-api-template.yml@kubedeploy
                parameters:
                  ENVIRONMENT: "mt"
                  APIM_SERVICE_CONNECTION: "$(armServiceConnectionTEST)"
                  API_ID: "$(apiId)"
                  APIM_FILES_DIRECTORY: "$(apimFilesDirectory)"
                  API_BASE_PATH: "$(apiBasePath)"

  - stage: "DeployApimApiPROD"
    displayName: "Deploy deploy APIM policy to PROD"
    dependsOn: ["PushToACR", "DeployApimApiMT"]
    condition: and(and(succeeded(), eq(variables['Build.SourceBranch'], variables['mainBranch'])), eq(\${{ parameters.deployToPROD }}, true))
    jobs:
    - deployment: Deploy
      displayName: "Deploy deploy APIM policy to PROD"
      pool:
        vmImage: $(vmImage)
      environment: 'prod'
      strategy:
        runOnce:
          deploy:
            steps:
              - template: template/apim-api-template.yml@kubedeploy
                parameters:
                  ENVIRONMENT: "prod"
                  APIM_SERVICE_CONNECTION: "$(armServiceConnectionPROD)"
                  API_ID: "$(apiId)"
                  APIM_FILES_DIRECTORY: "$(apimFilesDirectory)"
                  API_BASE_PATH: "$(apiBasePath)"
      `
            : '';

        const pipelineYaml = `# Generated from
# ${window.location.protocol}//${window.location.host}${window.location.pathname}${
            this.state.params
        }
# You can allways visit this link to download this pipeline.

trigger:
- main
- master
- release/*

pr: none

parameters:
- name: deployToUAT
  displayName: Deploy to UAT?
  type: boolean
  default: ${this.state.uat}
- name: deployToMT
  displayName: Deploy to MT?
  type: boolean
  default: ${this.state.mt}
- name: deployToPROD
  displayName: Deploy to PROD?
  type: boolean
  default: ${this.state.prod}

variables:
  aksNamespace: ${this.state.namespace.trim()}
  armServiceConnectionPROD: ${this.state.armServiceConnectionPROD.trim()}
  armServiceConnectionTEST: ${this.state.armServiceConnectionTEST.trim()}
  deploymentPhase: "deploy,monitor,activate"
  deploymentType: "bluegreen"
  gitServiceConnection: ${this.state.gitServiceConnection.trim()}
  identityBinding: ${this.state.identity.trim()}
  imageAndTag: '$(imageName):$(Build.BuildNumber)'
  imageName: ${this.state.docker.repository.trim()}/${this.state.docker.name.trim()}
  mainbranch: "refs/heads/main"
  registry: ${this.state.docker.registry.trim()}
  vmImage: ubuntu-20.04
${apimconfig}
resources:
  repositories:
    - repository: kubedeploy
      type: git
      name: DevEx/kubedeploy
      ref: refs/heads/main

stages:
  - stage: "PushToACR"
    displayName: "Push docker image to ACR"
    jobs:
    - job: BuildAndPush
      displayName: "Build and push Docker Image to ACR"
      pool:
        vmImage: $(vmImage)
      steps:
      - task: AzureCLI@2
        name: dockerPush
        displayName: "Docker build and push"
        inputs:
          azureSubscription: $(armServiceConnectionPROD)
          scriptType: "bash"
          scriptLocation: "inlineScript"
          inlineScript: |
            set -eo pipefail
            az acr login -n acrvippsakscmn
            docker build -t $(registry)/$(imageName):$(Build.BuildNumber) .
            pushOutput=$(docker push $(registry)/$(imageAndTag))
            digest=$(echo $pushOutput | grep -o sha256:[0-9a-zA-Z]*)
            echo "Digest of docker push $(registry)/$(imageAndTag): $digest"
            echo "##vso[task.setvariable variable=digest;isOutput=true]$digest"

      - task: CopyFiles@2
        displayName: 'Copy values.yaml staging directory'
        inputs:
          contents: |
            $(Build.SourcesDirectory)/deploy/values.yaml
          targetFolder: $(Build.ArtifactStagingDirectory)

      - task: PublishBuildArtifacts@1
        displayName: "Publish deploy / values.yaml"
        inputs:
          pathtoPublish: $(Build.ArtifactStagingDirectory)
          ArtifactName: 'deploy'
          publishLocation: 'Container'
${publishApimApi}
  - stage: "DeployUAT"
    displayName: "Deploy deploy to UAT"
    dependsOn: ["PushToACR"]
    condition: and(succeeded(), eq(\${{ parameters.deployToUAT }}, true))
    jobs:
    - deployment: Deploy
      displayName: "Deploy to UAT AKS with Kubedeploy"
      pool:
        vmImage: $(vmImage)
      environment: 'uat'
      variables:
        digest:  $[ stageDependencies.PushToACR.BuildAndPush.outputs['dockerPush.digest'] ]
      strategy:
        runOnce:
          deploy:
            steps:
            - bash: |
                echo "Digest of docker push from previous job: $(digest)"
              displayName: 'Print digest'
            - template: template/kubedeploy-template-v2.yml@kubedeploy
              parameters:
                ENVIRONMENT: "uat"
                NAMESPACE: "$(aksNamespace)"
                IDENTITY_BINDING: "$(identityBinding)"
                DEPLOYMENT_TYPE: "$(deploymentType)"
                DEPLOYMENT_PHASE: "$(deploymentPhase)"
                IMAGE_NAME: "$(imageName)@$(digest)"
                VALUES_PATH: "$(Pipeline.Workspace)/deploy/deploy/values.yaml"
                BUILD_NAME: "$(Build.BuildNumber)"
                ACR_AKS_SERVICE_CONNECTION: "$(armServiceConnectionTEST)"
                GITHUB_SERVICE_CONNECTION: "$(gitServiceConnection)"
                ${this.state.runAsUserId > 0 ? 'POLICY: security_context.allow=true' : ''}

  - stage: "DeployMT"
    displayName: "Deploy deploy to MT"
    dependsOn: ["PushToACR", "DeployUAT"]
    jobs:
    - deployment: Deploy
      displayName: "Deploy to MT AKS with Kubedeploy"
      pool:
        vmImage: $(vmImage)
      environment: 'mt'
      variables:
        digest:  $[ stageDependencies.PushToACR.BuildAndPush.outputs['dockerPush.digest'] ]
      strategy:
        runOnce:
          deploy:
            steps:
            - bash: |
                echo "Digest of docker push from previous job: $(digest)"
              displayName: 'Print digest'
            - template: template/kubedeploy-template-v2.yml@kubedeploy
              parameters:
                ENVIRONMENT: "mt"
                NAMESPACE: "$(aksNamespace)"
                IDENTITY_BINDING: "$(identityBinding)"
                DEPLOYMENT_TYPE: "$(deploymentType)"
                DEPLOYMENT_PHASE: "$(deploymentPhase)"
                IMAGE_NAME: "$(imageName)@$(digest)"
                VALUES_PATH: "$(Pipeline.Workspace)/deploy/deploy/values.yaml"
                BUILD_NAME: "$(Build.BuildNumber)"
                ACR_AKS_SERVICE_CONNECTION: "$(armServiceConnectionTEST)"
                GITHUB_SERVICE_CONNECTION: "$(gitServiceConnection)"
                ${this.state.runAsUserId > 0 ? 'POLICY: security_context.allow=true' : ''}

  - stage: "DeployPROD"
    displayName: "Deploy deploy to Prod"
    dependsOn: ["PushToACR", "DeployMT"]
    condition: and(and(succeeded(), eq(variables['Build.SourceBranch'], variables['mainBranch'])), eq(\${{ parameters.deployToPROD }}, true))
    jobs:
    - deployment: Deploy
      displayName: "Deploy to Prod AKS with Kubedeploy"
      pool:
        vmImage: $(vmImage)
      environment: 'prod'
      variables:
        digest:  $[ stageDependencies.PushToACR.BuildAndPush.outputs['dockerPush.digest'] ]
      strategy:
        runOnce:
          deploy:
            steps:
            - bash: |
                echo "Digest of docker push from previous job: $(digest)"
              displayName: 'Print digest'
            - template: template/kubedeploy-template-v2.yml@kubedeploy
              parameters:
                ENVIRONMENT: "prod"
                NAMESPACE: "$(aksNamespace)"
                IDENTITY_BINDING: "$(identityBinding)"
                DEPLOYMENT_TYPE: "$(deploymentType)"
                DEPLOYMENT_PHASE: "$(deploymentPhase)"
                IMAGE_NAME: "$(imageName)@$(digest)"
                VALUES_PATH: "$(Pipeline.Workspace)/deploy/deploy/values.yaml"
                BUILD_NAME: "$(Build.BuildNumber)"
                ACR_AKS_SERVICE_CONNECTION: "$(armServiceConnectionPROD)"
                GITHUB_SERVICE_CONNECTION: "$(gitServiceConnection)"
                ${this.state.runAsUserId > 0 ? 'POLICY: security_context.allow=true' : ''}
  ${deployApimApi}`;

        const policyYaml = `<policies>
    <inbound>
        <base />
        <set-backend-service base-url='@(context.Variables.GetValueOrDefault("aksBackendUrl", "{{AKSBackendUrl}}") + "${this.state.ingress.path}")' />
    </inbound>
    <backend>
        <base />
    </backend>
    <outbound>
        <base />
    </outbound>
    <on-error>
        <base />
    </on-error>
</policies>
      `;

        const apiPolicyName = `${this.state.apim.apiId}.policy.xml`;
        /* jshint ignore:start */
        return (
            <main className="body">
                <div className="container">
                    <header>
                        <Heading1>Application Release Pipeline</Heading1>
                        <p>
                            <b>
                                This page helps you create a standard release pipeline for{' '}
                                <a href="https://dev.azure.com" rel="noreferrer noopener" target="_blank" className={`${link({inline: true})}`}>Azure DevOps</a>.
                            </b>
                            <br />
                            The release pipeline is built to put your application to kubernetes with
                            or without a correspoing API release to{' '}
                            <a href="https://azure.microsoft.com/nb-no/services/api-management/" rel="noreferrer noopener" target="_blank" className={`${link({inline: true})}`}>
                                APIM
                            </a>
                            . You must have your Docker image ready as a <code>Dockerfile</code>{' '}
                            before you can start. You must also have a <code>values.yaml</code> in
                            directory deploy on your git root.
                        </p>
                        <p>
                            When you are done you will get a new file{' '}
                            <code>azure-pipelines.yml</code> which you must check in to your repo.
                        </p>
                    </header>
                    <small>
                        This pipeline is based on{' '}
                        <a href="https://github.com/vippsas/canary/blob/main/build-and-deploy-pipeline.yml" rel="noreferrer noopener" target="_blank" className={`${link({inline: true})}`}>
                            vippsas / canary / deploy / build-and-deploy-pipeline.yml
                        </a>
                    </small>
                    <br />
                    <br />

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="docker-image">docker image</label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="docker-image"
                                defaultValue={this.state.docker.name}
                                required
                                onChange={this.handleDockerName}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="aks-service-connection-name-test">
                                Plattform Test service connection
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="aks-service-connection-name-test"
                                defaultValue={this.state.armServiceConnectionTEST}
                                required
                                placeholder="vipps-platform-VCE-UAT"
                                onChange={this.handleArmAksServiceConnectionTEST}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="aks-service-connection-name-prod">
                                Plattform Prod service connection
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="aks-service-connection-name-prod"
                                defaultValue={this.state.armServiceConnectionPROD}
                                required
                                placeholder="vipps-platform-VCE-PROD"
                                onChange={this.handleArmAksServiceConnectionPROD}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="git-service-connection-name">
                                git service connection
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="git-service-connection-name"
                                defaultValue={this.state.gitServiceConnection}
                                required
                                placeholder="github-DevOpsProject"
                                onChange={this.handleGitServiceConnection}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="aks-namespace">kubernetes namespace</label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="aks-namespace"
                                defaultValue={this.state.namespace}
                                onChange={this.handleNamespace}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="identity">
                                run with{' '}
                                <a href="https://github.com/Azure/aad-pod-identity#aad-pod-identity" rel="noreferrer noopener" target="_blank" className={`${link({inline: true})}`}>
                                    identity
                                </a>
                            </label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="identity"
                                defaultValue={this.state.identity}
                                required
                                onChange={this.handleIdentity}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label>Default environments to deploy to</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1">
                            <label>uat</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                type="checkbox"
                                checked={this.state.uat}
                                onChange={this.handleUat}
                            />
                        </div>

                        <div className="col-md-1">
                            <label>mt</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                type="checkbox"
                                checked={this.state.mt}
                                onChange={this.handleMt}
                            />
                        </div>

                        <div className="col-md-1">
                            <label>prod</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                type="checkbox"
                                checked={this.state.prod}
                                onChange={this.handleProd}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        <div className="col">
                            <h2>API Management (APIM)</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="apim-enabled">create API release</label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="checkbox"
                                name="apim-enabled"
                                checked={this.state.apim.enabled}
                                onChange={this.handleApimEnabled}
                            ></input>
                        </div>
                    </div>

                    <div className="row" title="This field is called Name in the azure portal">
                        <div className="col-md-4">
                            <label htmlFor="apim-api-id">API ID</label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="apim-api-id"
                                defaultValue={this.state.apim.apiId}
                                required={this.state.apim.enabled}
                                pattern="[A-Za-z0-9-]+"
                                placeholder="myservice"
                                onChange={this.handleApimApiId}
                            ></input>
                        </div>
                    </div>

                    <div
                        className="row"
                        title="This field is called API URL Suffix in the azure portal"
                    >
                        <div className="col-md-4">
                            <label htmlFor="apim-basepath">base path</label>
                            <p>This is the endpoint after https://api.vipps.no</p>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="apim-basepath"
                                defaultValue={this.state.apim.apiBasePath}
                                required={this.state.apim.enabled}
                                pattern="(\/|[A-Za-z0-9-])+"
                                placeholder="/myservice"
                                onChange={this.handleApimApiBasePath}
                            ></input>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="apim-ingress-path">path (used by nginx ingress)</label>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="apim-ingress-path"
                                defaultValue={this.state.ingress.path}
                                pattern="(\/|[A-Za-z0-9-])+"
                                placeholder="/my-service"
                                onChange={this.handleIngressPath}
                            ></input>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggle('pipeline')}
                                        className={classnames({
                                            active: this.state.activeTab === 'pipeline',
                                        })}
                                    >
                                        <FontAwesomeIcon icon="chevron-down" /> 
                                        pipeline
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggle('api-policy')}
                                        className={classnames({
                                            active: this.state.activeTab === 'api-policy',
                                        })}
                                    >
                                        <FontAwesomeIcon icon="chevron-down" /> 
                                        APIM API Policy
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="pipeline">
                                    <ButtonGroup className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                this.copy(pipelineYaml, 'azure-pipelines.yml')
                                            }
                                        >
                                            copy to clipboard
                                        </Button>
                                        <Button
                                            type="button"
                                        >
                                            <a
                                                href={this.download(pipelineYaml)}
                                                download="azure-pipelines.yml"
                                                className={link()}
                                            >
                                                Download azure-pipelines.yml
                                            </a>
                                        </Button>
                                    </ButtonGroup>
                                    <code>
                                        <pre className="code">{pipelineYaml}</pre>
                                    </code>
                                </TabPane>
                                <TabPane tabId="api-policy">
                                    <ButtonGroup className={`${marginBottomMedium()} ${marginTopMedium()}`}>
                                        <Button
                                            type="button"
                                            onClick={() => this.copy(pipelineYaml, apiPolicyName)}
                                        >
                                            copy to clipboard
                                        </Button>
                                        <Button
                                            type="button">
                                            <a href={this.download(policyYaml)} download={apiPolicyName} className={link()}>
                                                Download {apiPolicyName}
                                            </a>
                                        </Button>
                                        
                                    </ButtonGroup>
                                    <code>
                                        <pre className="code">{policyYaml}</pre>
                                    </code>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </main>
        );
        /* jshint ignore:end */
    }
}

export default NewPipeline;
