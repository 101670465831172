import React from 'react';
import './Probe.css';

class Probe extends React.Component {
    constructor(props) {
        super(props);
        this.handleInitialDelaySeconds = this.handleInitialDelaySeconds.bind(this);
        this.handlePath = this.handlePath.bind(this);
        this.handlePollInteval = this.handlePollInteval.bind(this);
        this.handlePort = this.handlePort.bind(this);
        this.handleProtocol = this.handleProtocol.bind(this);
        this.handleTimeoutSeconds = this.handleTimeoutSeconds.bind(this);
    }

    getState(props) {
        return {
            initialDelaySeconds: props.initialDelaySeconds,
            path: props.path,
            pollInterval: props.pollInterval,
            port: props.port,
            protocol: props.protocol,
            timeoutSeconds: props.timeoutSeconds,
        };
    }

    handleInitialDelaySeconds(e) {
        const state = this.getState(this.props);
        state.initialDelaySeconds = e.target.value;
        this.props.onChange(state);
    }

    handlePath(e) {
        const state = this.getState(this.props);
        state.path = e.target.value;
        this.props.onChange(state);
    }

    handlePollInteval(e) {
        const state = this.getState(this.props);
        state.pollInterval = e.target.value;
        this.props.onChange(state);
    }

    handlePort(e) {
        const state = this.getState(this.props);
        state.port = e.target.value;
        this.props.onChange(state);
    }

    handleProtocol(e) {
        const state = this.getState(this.props);
        state.protocol = e.target.value;
        this.props.onChange(state);
    }

    handleTimeoutSeconds(e) {
        const state = this.getState(this.props);
        state.timeoutSeconds = e.target.value;
        this.props.onChange(state);
    }

    render() {
        /* jshint ignore:start */
        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="initial-delay-seconds">Initial delay (s)</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            type="number"
                            name="initial-delay-seconds"
                            data-testid="initial-delay-seconds"
                            defaultValue={this.props.initialDelaySeconds}
                            onChange={this.handleInitialDelaySeconds}
                        ></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="probe-poll">poll interval (s)</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            type="number"
                            name="probe-poll"
                            data-testid="probe-poll"
                            defaultValue={this.props.pollInterval}
                            onChange={this.handlePollInteval}
                        ></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="probe-port">port</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            type="number"
                            name="probe-port"
                            data-testid="probe-port"
                            defaultValue={this.props.port}
                            min="80"
                            max="65535"
                            step="1"
                            onChange={this.handlePort}
                        ></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="probe-protocol">protocol</label>
                    </div>
                    <div className="col-md-9">
                        <select
                            name="probe-protocol"
                            data-testid="probe-protocol"
                            value={this.props.protocol}
                            onChange={this.handleProtocol}
                        >
                            <option value="http">HTTP</option>
                            <option value="tcp">TCP</option>
                        </select>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="probe-path">path</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            type="text"
                            name="probe-path"
                            data-testid="probe-path"
                            defaultValue={this.props.path}
                            placeholder="/health"
                            pattern="([a-z0-9]+)"
                            onChange={this.handlePath}
                        ></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="timeout-seconds">timeout (s)</label>
                    </div>
                    <div className="col-md-9">
                        <input
                            type="number"
                            name="timeout-seconds"
                            data-testid="timeout-seconds"
                            defaultValue={this.props.timeoutSeconds}
                            onChange={this.handleTimeoutSeconds}
                        ></input>
                    </div>
                </div>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default Probe;
