import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import SecurityPipeline from './SecurityPipeline';
import FlowNavigation from '../components/FlowNavigation';

class FlowThree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            docker: {
                name: '',
            },
            gitServiceConnection: '',
        };
        this.handleDockerNameChange = this.handleDockerNameChange.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const name = params.get('name') || this.state.name;
        const gitsc = params.get('gitsc') || this.state.gitServiceConnection;
        this.setState({
            docker: { name: name },
            gitServiceConnection: gitsc,
        });
    }

    handleDockerNameChange(e) {
        this.setState({
            docker: {
                name: e.name,
            },
        });
    }

    render() {
        /* jshint ignore:start */
        return (
            <div>
                <FlowNavigation 
                    previous="/start/build-and-release"
                    next="/start/finish"
                    currentStep={4}
                    numberOfSteps={5}
                ></FlowNavigation>
                <SecurityPipeline
                    location={this.props.location}
                    onDockerNameChange={this.handleDockerNameChange}
                />
            </div>
        );
        /* jshint ignore:end */
    }
}

export default FlowThree;
