import './FlowFive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Finish from './Finish';
import FlowNavigation from '../components/FlowNavigation';

class FlowFive extends React.Component {
    render() {
        /* jshint ignore:start */
        return (
            <div>
                <FlowNavigation 
                    previous="/start/security-pipeline"
                    currentStep={5}
                    numberOfSteps={5}
                ></FlowNavigation>
                <Finish />
            </div>
        );
        /* jshint ignore:end */
    }
}

export default FlowFive;
