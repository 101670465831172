import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import NewValuesYaml from './NewValuesYaml';
import FlowNavigation from '../components/FlowNavigation';

const defaultRunAsUserId = 1000;

const defaultIngress = {
    enabled: true,
    proxyIngressEnabled: false,
    port: 8080,
    path: '',
    extendedBodySize: false,
};

class FlowOne extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ingress: defaultIngress,
            name: '',
            runAsUserId: defaultRunAsUserId,
        };
        this.handleNewValuesYaml = this.handleNewValuesYaml.bind(this);
    }

    handleNewValuesYaml(e) {
        this.setState({
            ingress: e.ingress,
            name: e.name,
            runAsUserId: e.runAsUserId,
        });
    }

    render() {
        var search = [];
        if (this.state.name !== '') {
            search.push(`identity=${this.state.name}`);
            search.push(`name=${this.state.name}`);
        }

        if (this.state.ingress.path !== '') {
            search.push(`ingress=${encodeURIComponent(JSON.stringify(this.state.ingress))}`);
        }

        if (this.state.runAsUserId !== defaultRunAsUserId) {
            search.push(`runasuserid=${this.state.runAsUserId.toString()}`);
        }

        const params = search.length > 0 ? `?${search.join('&')}` : '';
        /* jshint ignore:start */
        return (
            <div>
                <FlowNavigation
                    previous="/start/create-identity"
                    next={`/start/build-and-release${params}`}
                    currentStep={2}
                    numberOfSteps={5}
                >
                </FlowNavigation>

                <NewValuesYaml
                    location={this.props.location}
                    onChange={this.handleNewValuesYaml}
                ></NewValuesYaml>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default FlowOne;
