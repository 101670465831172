import React from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import './Resources.css';

const XSRequests = {
    cpu: '.1',
    cpuUnit: '',
    memory: '35',
    memoryUnit: 'Mi',
};

const SRequests = {
    cpu: '.20',
    cpuUnit: '',
    memory: '90',
    memoryUnit: 'Mi',
};

const MRequests = {
    cpu: '.25',
    cpuUnit: '',
    memory: '170',
    memoryUnit: 'M',
};

const LRequests = {
    cpu: '.25',
    cpuUnit: '',
    memory: '600',
    memoryUnit: 'M',
};

const XLRequests = {
    cpu: '.5',
    cpuUnit: '',
    memory: '780',
    memoryUnit: 'M',
};

const XSLimits = {
    cpu: '.5',
    cpuUnit: '',
    memory: '75',
    memoryUnit: 'M',
};

const SLimits = {
    cpu: '1',
    cpuUnit: '',
    memory: '150',
    memoryUnit: 'M',
};

const MLimits = {
    cpu: '2',
    cpuUnit: '',
    memory: '200',
    memoryUnit: 'M',
};

const LLimits = {
    cpu: '4',
    cpuUnit: '',
    memory: '780',
    memoryUnit: 'M',
};

const XLLimits = {
    cpu: '4',
    cpuUnit: '',
    memory: '2.68',
    memoryUnit: 'G',
};

class Resources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: -1,
            isLimits: false,
        };
        this.handleSize = this.handleSize.bind(this);
        this.handleCpu = this.handleCpu.bind(this);
        this.handleCpuUnit = this.handleCpuUnit.bind(this);
        this.handleMemory = this.handleMemory.bind(this);
        this.handleMemoryUnit = this.handleMemoryUnit.bind(this);
        this.refCPU = React.createRef();
        this.refCPUUnit = React.createRef();
        this.refMemory = React.createRef();
        this.refMemoryUnit = React.createRef();
    }

    getState(props) {
        return {
            cpu: props.cpu,
            cpuUnit: props.cpuUnit,
            memory: props.memory,
            memoryUnit: props.memoryUnit,
        };
    }

    handleCpu(e) {
        const state = this.getState(this.props);
        state.cpu = e.target.value;
        this.props.onChange(state);
    }

    handleCpuUnit(e) {
        const state = this.getState(this.props);
        state.cpuUnit = e.target.value;
        this.props.onChange(state);
    }

    handleMemory(e) {
        const state = this.getState(this.props);
        state.memory = e.target.value;
        this.props.onChange(state);
    }

    handleMemoryUnit(e) {
        const state = this.getState(this.props);
        state.memoryUnit = e.target.value;
        this.props.onChange(state);
    }

    handleSize(size) {
        var resources;
        if (this.props.limits) {
            switch (size) {
                case 1:
                    resources = XSLimits;
                    break;
                case 2:
                    resources = SLimits;
                    break;
                case 3:
                    resources = MLimits;
                    break;
                case 4:
                    resources = LLimits;
                    break;
                case 5:
                    resources = XLLimits;
                    break;
                default:
                    break;
            }
        } else {
            switch (size) {
                case 1:
                    resources = XSRequests;
                    break;
                case 2:
                    resources = SRequests;
                    break;
                case 3:
                    resources = MRequests;
                    break;
                case 4:
                    resources = LRequests;
                    break;
                case 5:
                    resources = XLRequests;
                    break;
                default:
                    break;
            }
        }

        this.refCPU.current.value = resources.cpu;
        this.refCPUUnit.current.value = resources.cpuUnit;
        this.refMemory.current.value = resources.memory;
        this.refMemoryUnit.current.value = resources.memoryUnit;

        this.props.onChange(resources);
        this.setState({
            size: size,
        });
    }

    render() {
        /* jshint ignore:start */
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <h4>Size</h4>
                    </div>
                </div>
                <div
                    className="row range-size"
                    style={{ display: this.props.showSizes ? '' : 'none' }}
                >
                    <div className="col">
                        <button
                            type="button"
                            className={`size-1-${this.state.size === 1}`}
                            onClick={() => this.handleSize(1)}
                        >
                            XS
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            className={`size-1-${this.state.size === 2}`}
                            onClick={() => this.handleSize(2)}
                        >
                            S
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            className={`size-2-${this.state.size === 3}`}
                            onClick={() => this.handleSize(3)}
                        >
                            M
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            style={{ display: 'none' }}
                            className={`size-3-${this.state.size === 4}`}
                            onClick={() => this.handleSize(4)}
                        >
                            L
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            style={{ display: 'none' }}
                            className={`size-4-${this.state.size === 5}`}
                            onClick={() => this.handleSize(5)}
                        >
                            XL
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="resources-cpu">cpu</label>
                    </div>
                    <div className="col-md-2">
                        <input
                            type="text"
                            name="resources-cpu"
                            data-testid="resources-cpu"
                            defaultValue={this.props.cpu}
                            onChange={this.handleCpu}
                            ref={this.refCPU}
                            className="input-medium"
                        ></input>
                    </div>
                    <div className="col-md-1">
                        <select
                            name="probe-protocol"
                            value={this.props.cpuUnit}
                            ref={this.refCPUUnit}
                            onChange={this.handleCpuUnit}
                        >
                            <option value="m">milli-CPU</option>
                            <option value="">fraction</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <span
                            role="img"
                            aria-label="tool tip CPU"
                            id="cpu-tip"
                            className="explainer"
                        >
                            ❓
                        </span>
                        <UncontrolledCollapse toggler="#cpu-tip">
                            <p>
                                CPUs are measured as virtual CPU cores. Valid units are fraction
                                (without a unit suffix) and milli-CPUs with an m. there are 1000m in
                                one CPU core.
                            </p>
                            <br />
                            <p>
                                For example a full dual core CPU is measured in{' '}
                                <code>cpu: 2000m</code> or <code>cpu: 2</code>
                            </p>
                        </UncontrolledCollapse>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="resources-memory">memory</label>
                    </div>
                    <div className="col-md-2">
                        <input
                            type="text"
                            name="resources-memory"
                            defaultValue={this.props.memory}
                            ref={this.refMemory}
                            onChange={this.handleMemory}
                            className="input-medium"
                        ></input>
                    </div>
                    <div className="col-md-1">
                        <select
                            name="probe-protocol"
                            value={this.props.memoryUnit}
                            ref={this.refMemoryUnit}
                            onChange={this.handleMemoryUnit}
                        >
                            <option value="M">M</option>
                            <option value="Mi">Mi</option>
                            <option value="G">G</option>
                            <option value="Gi">Gi</option>
                        </select>
                    </div>

                    <div className="col-md-6">
                        <span
                            role="img"
                            aria-label="tool tip memory"
                            id="memory-tip"
                            className="explainer"
                        >
                            ❓
                        </span>
                        <UncontrolledCollapse toggler="#memory-tip">
                            <p>
                                Kubernetes accepts both SI notation (M,G) and{' '}
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://en.wikipedia.org/wiki/Binary_prefix"
                                >
                                    Binary notation
                                </a>{' '}
                                (Mi,Gi) for memory definition.
                            </p>
                            <p>
                                To limit memory at 256MB, you can assign <code>268.4M</code> (SI
                                notation) or <code>256Mi</code> (Binary notation).
                            </p>
                        </UncontrolledCollapse>
                    </div>
                </div>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default Resources;
