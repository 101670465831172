import './Start.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import React from 'react';

class Start extends React.Component {
    render() {
        /* jshint ignore:start */
        return (
            <div className="root">
                <div className="start">
                    <div className="top">
                        <h1>For your convenience 🤵‍♀️</h1>
                    </div>
                    <main className="start">
                        <div className="grid-container">

                            <div className="card">
                                <button className="card-header">Azure VNets</button>
                                <div className="card-body">
                                    <p>Get a real-time overview of VNets in Azure.</p>
                                    Wondering what subnets are already in use, and which ones might
                                    be available? What other VNets is it peered to, and is the
                                    peering connected? Take a look here to help plan your new
                                    network!
                                    <Link to="/start/vnets" className="stretched-link"></Link>
                                </div>
                            </div>

                            <br/><p></p>

                            <p>
                                <b>Missing some stuff?</b>
                                <p>Don't worry, it's just moved!</p>
                                <p>The functionality previously found here can now be found in <a 
                                        href="https://vippstech.no/content"
                                        target="_blank"
                                        rel="noreferrer noopener">
                                        Backstage</a>. 
                                    <br/>
                                Let us know in Slack #platform-support if you have any questions :) 
                                </p>
                            </p>

                        </div>
                    </main>
                </div>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default Start;
