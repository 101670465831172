import React from 'react';
import { Heading1 } from '@vippsno/ds-typography';
import { link } from '@vippsno/ds-typography';
import { marginBottomMedium } from '@vippsno/ds-spacing';


class CreateIdentity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identity: '',
            identityAppTest: 'undicovered',
            identityAppProd: 'undicovered',
        };
        this.handleCreateManagedIdentity = this.handleCreateManagedIdentity.bind(this);
        this.handleIdentityChanged = this.handleIdentityChanged.bind(this);
    }

    componentDidMount() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const identity = params.get('identity');
        this.setState({ identity: identity });
    }

    handleIdentityChanged(e) {
        this.setState({ identity: e.target.value });
    }

    handleCreateManagedIdentity(e) {
        let myHeaders = new Headers();
        myHeaders.append('Accept', 'image/jpeg');
        fetch(
            'https://vsrm.dev.azure.com/vipps/Backend/_apis/release/definitions?api-version=6.1-preview.4',
            {
                method: 'POST',
                headers: {},
                mode: 'cors',
                body: {},
                credentials: {},
            }
        ).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        });
    }

    render() {
        /* jshint ignore:start */
        return (
        <div className="container">
            <Heading1>Application Security</Heading1>
            <div className="row">
                <div className="col">
                    <p>
                        <b>
                            You must have managed identities created to run in
                            Kubernetes (AKS). This allows your application to access
                            Azure resources. We run this routine once per
                            application.
                        </b>
                    </p>
                    <p>
                        You can create your own using the following 
                        <a href="https://dev.azure.com/vipps/Backend/_release?_a=releases&view=mine&definitionId=729" rel="noreferrer noopener" target="_blank" className={link()}>
                            release
                        </a>
                        . Click the "Create a new release" button and scroll down to the
                        variables section. Enter the required details and click create.
                    </p>
                    <img
                        alt="release pipeline parameters"
                        src="/img/pipeline-parameters.jpg"
                        className={marginBottomMedium() + " img-thumbnail"}
                        
                    />
                    <h2>Required details</h2>
                    <dl>
                        <dt>identity-name</dt>
                        <dd>The name of your application</dd>
                        <dt>namespace-name</dt>
                        <dd>The name of your team Kubernetes namespace</dd>
                    </dl>
                    <p>
                        This will now create managed identities for you with the
                        following names:
                        <br />
                        <code>&lt;myapp&gt;-app-test</code>
                        <br />
                        <code>&lt;myapp&gt;-app-prod</code>
                        <br />
                        <br />
                        You can now assign permissions to Azure resources using these
                        identities. E.g.
                        <br />
                        If you want your application to have access to an Azure Key
                        Vault, add <code>&lt;myapp&gt;-app-test</code> to the desired
                        resource.
                    </p>
                    <figure>
                        <img
                            alt="From the azure portal you can see and assign access to azure keyvault Access Polies. Here you can see <code>p2pmoney-app-test (highlighted with a yellow box) has access to GET and LIST Secret Permissions to keyvault p2pmoney-uat-kv"
                            src="/img/assign-access-policy-to-keyvault.jpg"
                            className={marginBottomMedium() + " img-thumbnail"}
                        />
                        <figcaption>
                            Here you can see <code>p2pmoney-app-test</code> (highlighted with a
                            yellow box) has access to GET and LIST Secret Permissions to
                            keyvault <code>p2pmoney-uat-kv</code>
                        </figcaption>
                    </figure>
                </div>
            </div>

            <div style={{ display: 'none' }}>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <label htmlFor="identity">identity</label>
                    </div>
                    <div className="col">
                        <input
                            type="text"
                            name="identity"
                            defaultValue={this.state.identity}
                            onChange={this.handleIdentityChanged}
                        />
                    </div>
                    <div className="col"></div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <div className={`mi-status ${this.state.identityAppTest}`}>
                            {this.state.identity}-app-test
                        </div>
                    </div>
                    <div className="col">
                        <div className={`mi-status ${this.state.identityAppProd}`}>
                            {this.state.identity}-app-prod
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <button
                            type="button"
                            className={`push create-identity-${(
                                this.state.identity === ''
                            ).toString()}`}
                            disabled={
                                this.state.identity === '' ||
                                this.state.identityAppProd === ''
                            }
                            onClick={this.handleCreateManagedIdentity}
                        >
                            Create Managed Identity
                        </button>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </div>
        );
        /* jshint ignore:end */
    }
}

export default CreateIdentity;
