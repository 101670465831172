import './CreateCronJobRelease.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React from 'react';

class CreateCronJobRelease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        /* jshint ignore:start */
        return (
            <div>
                <nav className="flow">
                    <span className="flow-item">
                        <Link to={'/start/cron-job'} title="CronJob">
                            <FontAwesomeIcon icon="chevron-left" size="3x" />
                        </Link>
                    </span>
                </nav>
                <main className="body">
                    <div className="container">
                        <header>
                            <h1>Release your CronJob</h1>
                        </header>
                        <p>
                            Right now we only have an example.
                            <br />
                            Look at, and adobt, this example accordingly
                        </p>
                        <pre className="code">
                            {`
parameters:
  - name: 'name'
    default: ''
  - name: dependsOn
    type: object
    default: []
  - name: 'environment'
    default: ''
  - name: 'aksNameSpace'
    default: ''
  - name: 'imageAndTag'
    default: ''
  - name: 'acrAksServiceConnection'
    default: ''
  - name: 'gitHubServiceConnection'
    default: ''
  - name: 'displayName'
    default: ''
  - name: 'vibeYamlPath'
    default: false
  - name: 'condition'
    default: ''
  - name: 'registry'
    default: 'acrvippsakscmn.azurecr.io'

jobs:
  - deployment: \$\{{ parameters.name }}
    condition: \$\{{ parameters.condition }}
    dependsOn: \$\{{ parameters.dependsOn }}
    displayName: \$\{{ parameters.displayName }}
    pool:
      vmImage: ubuntu-latest
    environment: \$\{{ parameters.environment }} # change to your env
    strategy:
      runOnce:
        deploy:
          steps:
            - task: AzureCLI@2
              displayName: "Replace image tag"
              inputs:
                scriptType: "bash"
                scriptLocation: "inlineScript"
                addSpnToEnvironment: true
                azureSubscription: \$\{{ parameters.acrAksServiceConnection }}
                # This script replaces the cronjob.image property in deploy/epayment-expire-payments-job/vibe.yml
                inlineScript: |
                  set -eo pipefail
                  echo "Replacing path to image in yaml file to \$\{{ parameters.registry }}/\$\{{ parameters.imageAndTag }}"
                  yq e -i '.cronjob.image = "\$\{{ parameters.registry }}/\$\{{ parameters.imageAndTag }}"' \$\{{ parameters.vibeYamlPath }}
            - template: pipeline/templates/template.yml@vops
              parameters:
                ENVIRONMENT: \$\{{ parameters.environment }}
                ACR_AKS_SERVICE_CONNECTION: \$\{{ parameters.acrAksServiceConnection }}
                SPEC: \$\{{ parameters.vibeYamlPath }}
                NAMESPACE: \$\{{ parameters.aksNamespace }}
`}
                        </pre>
                    </div>
                </main>
            </div>
        );
        /* jshint ignore:end */
    }
}

export default CreateCronJobRelease;
