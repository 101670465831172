import React from 'react';
import { Redirect } from 'react-router';
import { getUser } from './AuthenticationService';
import { getStoredPath, clearStoredPath } from './LocalRedirectUrlStorage';

class AadCallback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };
    }

    componentDidMount() {
        const user = getUser();
        this.setState({ user: user });
    }

    render() {
        let localUrl = getStoredPath();

        if (localUrl === null) {
            localUrl = '/';
        }

        clearStoredPath();

        return <Redirect to={localUrl} />;
    }
}
export default AadCallback;
